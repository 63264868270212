import { Col, Row } from 'antd';
import { FormikProps } from 'formik';

import { MButton } from '../FormComponents/MButton/MButton';

import * as Styles from './MFormSaveButton.styles';

export interface MFormSaveButtonProps<T> {
  isEditMode: boolean;
  form: FormikProps<T>;
  loading?: boolean;
  onSave: (form: FormikProps<T>) => void;
  saveButtonText?: string;
  onCancel?: (form: FormikProps<T>) => void;
  cancelButtonText?: string;
  testId?: string;
}

export function MFormSaveButton<T>({
  isEditMode,
  loading,
  onCancel,
  onSave,
  form,
  saveButtonText = 'Save',
  cancelButtonText = 'Cancel',
  testId,
}: MFormSaveButtonProps<T>) {
  return (
    <Row className={Styles.container}>
      {isEditMode && (
        <Col>
          <MButton
            testId={testId + '-cancel-button'}
            onClick={() => {
              if (onCancel) {
                onCancel(form);
              }
            }}
            type='tertiary'
            danger
            className={Styles.button}>
            {cancelButtonText}
          </MButton>
        </Col>
      )}
      <Col>
        <MButton
          testId={testId + '-save-button'}
          onClick={() => onSave(form)}
          loading={loading}
          type='secondary'
          disabled={!form.isValid}
          htmlType='submit'
          className={Styles.button}>
          {saveButtonText}
        </MButton>
      </Col>
    </Row>
  );
}
