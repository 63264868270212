import React, { useCallback, useEffect, useState } from 'react';

import { Col, Row } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Formik } from 'formik';
import _, { get } from 'lodash';
import moment from 'moment';
import { PlaidLinkOptions, usePlaidLink } from 'react-plaid-link';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import { cancelDeposit, cancelWithdraw } from 'src/actions/cashiering';
import { FilterBarConstant } from 'src/constants';
import { useAccountSelector } from 'src/hooks';
import {
  CancelTransferButton,
  ConfirmActionModal,
  EmptyTableState,
  FilterBar,
  FilterBarSample,
  LinkText,
  MTable,
  TransferDetailsModal,
  TransferStatusTag,
  TransferTypeCol,
} from 'src/lib';
import { CheckboxGroup } from 'src/lib/Filters/CheckboxFilter/CheckboxFilter';
import { MButton } from 'src/lib/FormComponents/MButton/MButton';
import CurrencyField from 'src/lib/Miscellaneous/FormattedFields/CurrencyField/CurrencyField';
import { Transfer, TransferStatus, TransferStatusLabel } from 'src/models/transfers.models';
import { ScreenBreakpoint } from 'src/styles';
import { getTransferSearch, getTransferStatusTagColor, onFixedTimelineFilterSelected } from 'src/utils';

import { getACHRelationships, getTransfers } from '../../../../actions';
import { FixedTimelineFilterValueType } from '../../../../lib/Filters/TimelineFilter/TimelineFilter';
import { MyAccountLayout } from '../../../../lib/Layout/MyAccountLayout/MyAccountLayout';
import { MyAccountSidebarOtherMenuItemKey } from '../../../../lib/Layout/MyAccountLayout/MyAccountSidebar';
import Spinner from '../../../../lib/Miscellaneous/Spinner';
import { MModal } from '../../../../lib/MModal/MModal';
import AchRelationships from '../AchRelationships/AchRelationships';
import CashieringModalContent from '../CashieringModalContent/CashieringModalContent';
import { DepositByCheck } from '../DepositByCheck/DepositByCheck';
import { DepositByWire } from '../DepositByWire/DepositByWire';
import { NewDepositModal } from '../NewDepositModal/NewDepositModal';
import { NewWithdrawalModal } from '../NewWithdrawalModal/NewWithdrawalModal';

import * as Styles from './BankAccountsAndFunding.styles';
import {
  ContextCardEnumTypes,
  getInitialValues,
  getLinkButtonText,
  getModalHeight,
  getModalTitle,
  getModalWidth,
  getSecondaryButtonText,
  onFormSubmit,
  onModalLinkButtonClick,
  onModalSecondaryButtonClick,
} from './constants';

import './popover.css';

export interface TransferViewModel {
  key: React.Key;
  date: string;
  type: JSX.Element;
  amount: JSX.Element;
  status: TransferStatus;
  cancel: JSX.Element | null;
  view: JSX.Element;
}

const transferListCols: ColumnsType<Omit<TransferViewModel, 'status'>> = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    width: '20%',
    render: (date: string) => <LinkText value={date} />,
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    width: '35%',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    width: '15%',
    align: 'right',
  },
  {
    key: 'cancel',
    dataIndex: 'cancel',
    width: '15%',
  },
  {
    key: 'view',
    dataIndex: 'view',
    width: '15%',
  },
];

const BankAccountsAndFundingUnmapped = ({ plaidLinkToken }: any): JSX.Element => {
  const defaultTimeline = FixedTimelineFilterValueType.THIS_YEAR;
  const defaultSortBy = FilterBarConstant.SortByCategory.Newest;
  const defaultValues: FilterBarSample = {
    sortBy: defaultSortBy,
    timeline: defaultTimeline,
    customTimeline: undefined,
    filterBy: [],
    show: [],
  };

  const location: any = useLocation();
  const achRelationships = useSelector((state: any) => state.cashiering.achRelationships.data);
  const loadingAchRelationships = useSelector((state: any) => state.cashiering.achRelationships.__requested);
  const bankTransactions: Transfer[] = useSelector((state: any) => state.cashiering.transfers?.data);
  const isLoadingBankTransactions = useSelector((state: any) => Boolean(state.cashiering.transfers?.__requested));

  const [loading, setLoading] = useState(false);
  const [contextCardVisible, setContextCardVisible] = useState<string>('');
  const [selectedBankLink, setSelectedBankLink] = useState<any>({});
  const [bankLinkPayload, setBankLinkPayload] = useState<any>({});
  const [correctBankType, setCorrectBankType] = useState<string>('');
  const [selectedWrongBankType, setSelectedWrongBankType] = useState<string>('');
  const [transactionList, setTransactionList] = useState<TransferViewModel[]>([]);
  const [filteredTransactions, setFilteredTransactions] = useState<TransferViewModel[]>([]);
  const [filters, setFilters] = useState<FilterBarSample>({ ...defaultValues });
  const [isCancelConfirmationModalOpen, setIsCancelConfirmationModalOpen] = useState<boolean>(false);
  const [isViewTransferDetailsModalOpen, setIsViewTransferDetailsModalOpen] = useState<boolean>(false);
  const [selectedTransfer, setSelectedTransfer] = useState<Transfer | null>(null);

  const [plaidBankLinkSuccessPayload, setPlaidBankLinkSuccessPayload] = useState<any>({});
  const [plaidAccounts, setPlaidAccounts] = useState<any>({});
  const [isNewDepositModalOpen, setIsNewDepositModalOpen] = useState<boolean>(false);
  const [isNewWithdrawalModalOpen, setIsNewWithdrawalModalOpen] = useState<boolean>(false);
  const { account } = useAccountSelector();

  const isMobile = useMediaQuery({ query: `(max-width: ${ScreenBreakpoint.mobile.max})` });

  const config: PlaidLinkOptions = {
    onSuccess: (publicToken: string, metadata: any) => {
      setLoading(false);

      handleSuccessPlaid({ publicToken, metadata });
    },
    onExit: () => {
      setContextCardVisible('');
      setLoading(false);
    },
    token: plaidLinkToken,
  };

  const dispatch = useDispatch();
  const { open, ready } = usePlaidLink(config);

  const onOpenCancelTransferModal = (transfer: Transfer) => {
    setSelectedTransfer(transfer);
    setIsCancelConfirmationModalOpen(true);
  };

  const onOpenTransferDetailsModal = (transfer: Transfer) => {
    setSelectedTransfer(transfer);
    setIsViewTransferDetailsModalOpen(true);
  };

  const onDeposit = () => {
    setIsNewDepositModalOpen(true);
  };

  const onWithdraw = () => {
    setIsNewWithdrawalModalOpen(true);
  };

  const onApproveFilters = useCallback((newFilters: FilterBarSample) => {
    setFilters({ ...newFilters });
  }, []);

  const handleSuccessPlaid = (plaidBankLinkSuccessPayload: any) => {
    let plaidAccounts = plaidBankLinkSuccessPayload.metadata.accounts.map((item: any) => {
      return {
        bankAccountType: `${item.subtype[0].toUpperCase()}${item.subtype.slice(1)}`,
        plaidAccountId: item.id,
        bankAccountMask: item.mask,
      };
    });

    if (achRelationships?.length > 0) {
      const bankAccountType = achRelationships[0].tradingBlockACHRelationship.bankAccountType;
      const selectedWrongBankType = plaidAccounts.find(
        (item: any) => item.bankAccountType.toLowerCase() === bankAccountType.toLowerCase(),
      )?.bankAccountType;

      if (!selectedWrongBankType) {
        setBankLinkPayload({
          plaidPublicToken: plaidBankLinkSuccessPayload.publicToken,
          institutionId: plaidBankLinkSuccessPayload.metadata.institution.institution_id,
          bankAccounts: plaidAccounts,
        });

        setContextCardVisible(ContextCardEnumTypes.BANK_LINK_CREATE);
      } else if (selectedWrongBankType && plaidAccounts.length === 2) {
        const correctBankType = selectedWrongBankType.toLowerCase() === 'checking' ? 'Savings' : 'Checking';

        setContextCardVisible(ContextCardEnumTypes.EXISTING_BANK_WARNING);
        setCorrectBankType(correctBankType);
        setSelectedWrongBankType(selectedWrongBankType);
        setPlaidBankLinkSuccessPayload(plaidBankLinkSuccessPayload);
        setPlaidAccounts(plaidAccounts);
      } else {
        setContextCardVisible(ContextCardEnumTypes.EXISTING_BANK_ERROR);
        setSelectedWrongBankType(selectedWrongBankType);
      }
    } else {
      setBankLinkPayload({
        plaidPublicToken: plaidBankLinkSuccessPayload.publicToken,
        institutionId: plaidBankLinkSuccessPayload.metadata.institution.institution_id,
        bankAccounts: plaidAccounts,
      });

      setContextCardVisible(ContextCardEnumTypes.BANK_LINK_CREATE);
    }
  };

  const mapTransferListToViewModelList = (): TransferViewModel[] => {
    return Array.isArray(bankTransactions)
      ? bankTransactions?.map((transfer: Transfer) => {
          const historyTransaction: TransferViewModel = {
            date: moment(transfer.updatedAt).local().utcOffset(7).utcOffset(0, true).format(),
            amount: (
              <CurrencyField
                value={transfer.amount}
                prefix={`${transfer.direction.isWithdrawal ? '-' : ''}$`}
                className={Styles.amountValue({ isNegative: transfer.direction.isWithdrawal })}
              />
            ),
            key: transfer.id,
            type: <TransferTypeCol value={transfer} testId='column-type-id' />,
            cancel: <CancelTransferButton value={transfer} onClick={onOpenCancelTransferModal} />,
            view: (
              <MButton
                type='tertiary'
                className={Styles.viewDetailsButton}
                onClick={() => onOpenTransferDetailsModal(transfer)}>
                View Details
              </MButton>
            ),
            status: transfer.status,
          };

          return historyTransaction;
        })
      : [];
  };

  const mapStatusLabelListToByFilterGroup = (): CheckboxGroup[] => {
    const options = Object.values(TransferStatusLabel).map(value => ({
      label: <TransferStatusTag value={value} />,
      value: `${value}_${FilterBarConstant.ByFilterCategory.MoneyTransfer}`,
      tag: {
        text: value,
        ...getTransferStatusTagColor(value),
      },
    }));

    return [{ title: 'Money Transaction(s)', options }];
  };

  const filterByStatus = (list: TransferViewModel[]) => {
    return list.filter((transfer: TransferViewModel) =>
      filters.filterBy?.some(aFilterValue => {
        const [value] = aFilterValue.toString().split('_');
        const label = transfer.status.label;

        return value === label;
      }),
    );
  };

  const applyFilters = () => {
    let filteredTransactionList = [...transactionList];

    if (filters.filterBy && filters.filterBy.length > 0)
      filteredTransactionList = filterByStatus(filteredTransactionList);

    if (filters.timeline !== undefined) {
      const timelineDate = onFixedTimelineFilterSelected(filters.timeline);
      filteredTransactionList = filteredTransactionList.filter(
        history =>
          moment(history.date).isSameOrBefore(timelineDate.endDate) &&
          moment(history.date).isSameOrAfter(timelineDate.startDate),
      );
    }

    if (filters.customTimeline !== undefined) {
      const [startDate, endDate] = filters.customTimeline;
      filteredTransactionList = filteredTransactionList.filter(
        history => moment(history.date).isSameOrBefore(endDate) && moment(history.date).isSameOrAfter(startDate),
      );
    }

    filteredTransactionList = _.orderBy(
      filteredTransactionList,
      ['date'],
      [filters.sortBy === FilterBarConstant.SortByCategory.Newest ? 'desc' : 'asc'],
    );

    return filteredTransactionList;
  };

  useEffect(() => {
    dispatch(getACHRelationships(account?.accountId));
  }, [dispatch]);

  useEffect(() => {
    if (!isLoadingBankTransactions) dispatch(getTransfers(account?.accountId, getTransferSearch(filters)));
  }, [filters.timeline, filters.customTimeline, account?.accountId]);

  useEffect(() => {
    if (!bankTransactions || isLoadingBankTransactions) return;
    const historyTransactions = mapTransferListToViewModelList();
    const orderedHistoryTransactions = _.orderBy(historyTransactions, ['date'], ['desc']);
    setTransactionList(orderedHistoryTransactions as any);
  }, [bankTransactions, isLoadingBankTransactions]);

  useEffect(() => {
    const transactionNewList = applyFilters();
    setFilteredTransactions(
      transactionNewList.map((item: TransferViewModel) => ({
        ...item,
        date: moment(item.date).format('MM/DD/yyyy'),
      })),
    );
  }, [filters, transactionList]);

  return (
    <MyAccountLayout
      sidebarMenuItemKey={MyAccountSidebarOtherMenuItemKey.MoneyTransfers}
      title='Bank Accounts & Funding'
      backlink={
        location?.state?.offerId && location?.state?.path
          ? { to: `/${location?.state?.path}/${location?.state?.offerId}`, label: 'Back to Offer' }
          : undefined
      }>
      {loadingAchRelationships !== null ? (
        <Spinner />
      ) : (
        <>
          <div className={Styles.label}> LINKED BANK ACCOUNTS</div>
          <AchRelationships
            achRelationships={achRelationships}
            selectedBankLink={selectedBankLink}
            loadingAchRelationships={loadingAchRelationships}
            setContextCardVisible={setContextCardVisible}
            setSelectedBankLink={setSelectedBankLink}
            onDeposit={onDeposit}
            onWithdraw={onWithdraw}
            accountId={account?.accountId}
            loading={loading}
            testId={'link-bank-accounts'}
          />
          <Col>
            <DepositByWire />
            <DepositByCheck />
            <p>
              If you need to withdrawal funds by wire or check please contact{' '}
              <a href='mailto:clientservices@myipo.com' target='_top'>
                clientservices@myipo.com
              </a>
            </p>
          </Col>
          {contextCardVisible !== '' && (
            <Formik
              enableReinitialize
              initialValues={getInitialValues(contextCardVisible, selectedBankLink)}
              onSubmit={values => {
                onFormSubmit(
                  contextCardVisible,
                  dispatch,
                  selectedBankLink,
                  values,
                  setContextCardVisible,
                  bankLinkPayload,
                  account?.accountId,
                );
              }}>
              {form => {
                return (
                  <MModal
                    customWidth={getModalWidth(contextCardVisible)}
                    customHeight={getModalHeight(contextCardVisible)}
                    verticalBottoms={
                      contextCardVisible === ContextCardEnumTypes.BANK_LINK_CREATE ||
                      contextCardVisible === ContextCardEnumTypes.BANK_LINK_EDIT ||
                      contextCardVisible === ContextCardEnumTypes.BANK_LINK_ACTION
                    }
                    form={contextCardVisible === ContextCardEnumTypes.BANK_LINK_ACTION ? undefined : form}
                    visible={contextCardVisible !== ''}
                    title={getModalTitle(contextCardVisible)}
                    primaryButtonText={getSecondaryButtonText(contextCardVisible)}
                    tertiaryButtonText={getLinkButtonText(contextCardVisible)}
                    onTertiaryButtonClick={() => {
                      onModalLinkButtonClick(contextCardVisible, form, setContextCardVisible);
                    }}
                    onClose={() => {
                      form.resetForm();
                      setContextCardVisible('');
                    }}
                    onPrimaryButtonClick={() =>
                      onModalSecondaryButtonClick(form, setContextCardVisible, ready, open, setBankLinkPayload, {
                        contextCardVisible,
                        plaidBankLinkSuccessPayload,
                        plaidAccounts,
                        selectedWrongBankType,
                        selectedBankLink,
                      })
                    }>
                    <div>
                      <CashieringModalContent
                        contextCardVisible={contextCardVisible}
                        form={form}
                        data={{
                          correctBankType,
                          selectedWrongBankType,
                          selectedBankLink,
                        }}
                      />
                    </div>
                  </MModal>
                );
              }}
            </Formik>
          )}
          <Row align='middle'>
            <Col span={24} sm={18} xs={18} className={Styles.label}>
              DEPOSITS & WITHDRAWALS
            </Col>
            <Col span={24} sm={6} xs={6} md={24}>
              <FilterBar
                onApprove={onApproveFilters}
                defaultValue={defaultValues}
                initValue={filters}
                byFilter={mapStatusLabelListToByFilterGroup()}
              />
            </Col>
            <Col span={24} className={Styles.tableContainer}>
              <MTable
                testId={'items-table'}
                loading={isLoadingBankTransactions}
                columns={transferListCols}
                data={filteredTransactions}
                pagination={{ position: ['bottomRight'] }}
                scroll={isMobile ? { x: 180 } : {}}
                emptyState={<EmptyTableState type='money transfers' />}
              />
            </Col>
          </Row>
          {isNewDepositModalOpen && (
            <NewDepositModal
              isOpen={isNewDepositModalOpen}
              onClose={() => setIsNewDepositModalOpen(false)}
              selectedBankAccount={selectedBankLink}
            />
          )}
          {isNewWithdrawalModalOpen && (
            <NewWithdrawalModal
              isOpen={isNewWithdrawalModalOpen}
              onClose={() => setIsNewWithdrawalModalOpen(false)}
              selectedBankAccount={selectedBankLink}
            />
          )}
          {selectedTransfer && (
            <TransferDetailsModal
              value={selectedTransfer}
              isOpen={isViewTransferDetailsModalOpen}
              onClose={() => {
                setIsViewTransferDetailsModalOpen(false);
                setSelectedTransfer(null);
              }}
            />
          )}
          {selectedTransfer && (
            <ConfirmActionModal
              title='Cancel Confirmation'
              message='Are you sure you want to cancel this transfer?'
              isOpen={isCancelConfirmationModalOpen}
              onCancel={() => {
                setIsCancelConfirmationModalOpen(false);
                setSelectedTransfer(null);
              }}
              onOk={() => {
                if (selectedTransfer.direction.isDeposit) {
                  dispatch(cancelDeposit(selectedTransfer.id, account?.accountId));
                  setIsCancelConfirmationModalOpen(false);
                  setSelectedTransfer(null);

                  return;
                }
                dispatch(cancelWithdraw(selectedTransfer.id, account?.accountId));
                setIsCancelConfirmationModalOpen(false);
                setSelectedTransfer(null);
              }}
            />
          )}
        </>
      )}
    </MyAccountLayout>
  );
};

const mapStateToProps = (state: any) => ({
  plaidLinkToken: get(state, 'cashiering.plaidLinkToken.data.linkToken'),
});

export const BankAccountsAndFunding = connect(mapStateToProps, null)(BankAccountsAndFundingUnmapped);
