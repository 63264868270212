import { Col } from 'antd';
import { IdentityVerificationInstructions, MFormDateField, MFormTextField, MTooltip } from 'src/lib';
import { Account } from 'src/models';
import { Font, Spacing } from 'src/styles';

export interface AccountHolderPersonalInformationViewProps {
  account?: Account;
}

export const AccountHolderPersonalInformationView = ({ account }: AccountHolderPersonalInformationViewProps) => {
  const formatSsid = () => {
    const taxId = account?.primaryAccountHolder?.taxId;

    if (taxId) {
      return taxId.substring(0, 3) + ' - ' + taxId.substring(3, 5) + ' - ' + taxId.substring(5, 9);
    }

    return taxId;
  };

  return (
    <>
      <Col span={24} className={Spacing.mb12}>
        <MTooltip
          icon='info'
          title='Identity Verification Instructions'
          label='Please enter your name as shown on a goverment-issued ID'
          type='modal'
          className={Font.md}>
          <IdentityVerificationInstructions />
        </MTooltip>
      </Col>
      <MFormTextField label='First Name' value={account?.primaryAccountHolder?.firstName} />
      <MFormTextField label='Middle Initial or Middle Name' value={account?.primaryAccountHolder?.middleName} />
      <MFormTextField label='Last Name' value={account?.primaryAccountHolder?.lastName} />
      <MFormTextField label='Suffix' value={account?.primaryAccountHolder?.suffix} />
      <MFormTextField
        label='Social Security Number'
        value={formatSsid()}
        tooltip={{
          type: 'modal',
          title: 'Why do we need this?',
          content: (
            <div>
              <p>
                A social security number is required to comply with security regulatory requirements, My IPO must get
                information such as name, address, DOB, social security number, employment information and basic
                financial information.
              </p>
              <p>
                My IPO’s site is secure, and we do not share your personal information as per our privacy policy. As
                part of our regulatory requirements, we must follow very strict security measures as a financial firm.
                Your social security number is encrypted at account creation you will notice we only show the last 4
                digits of your SSN after logging into My IPO once your account is created.
              </p>
            </div>
          ),
        }}
      />
      <MFormDateField label='Date of Birth' value={account?.primaryAccountHolder?.dateOfBirth} />
      <MFormTextField label='Marital Status' value={account?.primaryAccountHolder?.maritalStatus?.label} />
      <MFormTextField label='Number of Dependents' value={account?.primaryAccountHolder?.numberOfDependents} />
    </>
  );
};
