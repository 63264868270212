import { PropsWithChildren } from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Col, Form, Row } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { MAlert } from 'src/lib';
import { ScreenBreakpoint } from 'src/styles';

import { MFormItemLabel } from '../MFormItemLabel/MFormItemLabel';

import * as Styles from './MFormItem.styles';

export interface MFormItemProps {
  label: string;
  tooltip?: {
    type: 'modal' | 'popover';
    title?: string;
    content: string | JSX.Element;
  };
  align?: 'horizontal' | 'vertical';
  weight?: 'light' | 'medium';
  alert?: string;
  className?: string;
  testId?: string;
}

export const MFormItem = ({
  label,
  tooltip,
  children,
  align = 'horizontal',
  weight = 'medium',
  alert,
  className = ' ',
  testId,
}: PropsWithChildren<MFormItemProps>) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${ScreenBreakpoint.mobile.max})` });

  return (
    <Form.Item colon={false}>
      <Row className={Styles.contentContainer(align)}>
        <Col span={isMobile ? 24 : align === 'horizontal' ? 12 : 24} className={Styles.col}>
          <MFormItemLabel testId={testId} value={label} tooltip={tooltip} weight={weight} />
        </Col>
        <Col
          span={isMobile ? 24 : align === 'horizontal' ? 12 : 24}
          className={Styles.col.concat(' ').concat(className).trimEnd()}>
          {children}
        </Col>
        <Col span={24} className={Styles.col}>
          {alert && (
            <Col span={24}>
              <MAlert type='error' description={alert} icon={<ExclamationCircleOutlined />} />
            </Col>
          )}
        </Col>
      </Row>
    </Form.Item>
  );
};
