import { MFormItem } from '../MFormItem/MFormItem';
import { MSwitch, MSwitchProps } from '../MSwitch/MSwitch';

import * as Styles from './MFormSwitch.styles';

export interface MFormSwitchProps extends MSwitchProps {
  label: string;
  alignment?: 'left' | 'right';
}

export const MFormSwitch = ({
  label,
  checked,
  defaultChecked,
  onChange,
  loading,
  disabled,
  alignment = 'left',
}: MFormSwitchProps) => {
  return (
    <MFormItem label={label} className={Styles.mSwitch(alignment)}>
      <MSwitch
        disabled={disabled}
        checked={checked}
        defaultChecked={defaultChecked}
        onChange={onChange}
        loading={loading}
      />
    </MFormItem>
  );
};
