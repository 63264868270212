import { MFormItem } from '../MFormItem/MFormItem';
import { MRadioGroup, MRadioGroupProps } from '../MRadioGroup/MRadioGroup';

export interface MFormRadioGroupProps extends MRadioGroupProps {
  label: string;
  align?: 'horizontal' | 'vertical';
}

export const MFormRadioGroup = ({
  label,
  onChange,
  options,
  value,
  defaultValue,
  error,
  disabled,
  align,
  testId,
}: MFormRadioGroupProps) => {
  return (
    <MFormItem label={label} align='vertical'>
      <MRadioGroup
        testId={testId}
        align={align}
        onChange={onChange}
        options={options}
        value={value}
        defaultValue={defaultValue}
        error={error}
        disabled={disabled}
      />
    </MFormItem>
  );
};
