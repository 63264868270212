import * as Styles from './MFormItemError.styles';

export interface MFormItemErrorProps {
  value?: string;
}

export const MFormItemError = ({ value }: MFormItemErrorProps) => {
  if (value) {
    return <span className={Styles.error}>{value}</span>;
  }

  return null;
};
