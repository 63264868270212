import { useAccountSelector } from 'src/hooks';
import { MTooltip } from 'src/lib';
import { FontWeight } from 'src/styles';

export const DepositByCheck = () => {
  const { account } = useAccountSelector();

  const getAccountHolderInstructions = () => {
    if (account?.accountNumber) {
      return `Apex Clearing FBO (${account?.primaryAccountHolder?.lastName}, 
      ${account?.primaryAccountHolder?.firstName}, ${account.accountNumber})`;
    }

    return `Apex Clearing FBO (last name, first name, full My IPO account number)`;
  };

  return (
    <>
      <MTooltip type='modal' title='Deposit Funds By Check' label='Deposit Funds By Check' icon='info' showIcon>
        <>
          <p>
            Paper check deposits received before 1:00pm CT are processed same day. Please refer to the check funding
            instructions below to avoid having your check returned to you as unacceptable.
          </p>

          <strong>Make check(s) payable to:</strong>
          <p>{getAccountHolderInstructions()}</p>

          <strong>Mail checks to:</strong>
          <p>
            <span>TradingBlock</span>
            <br />
            <span>Attn. Cashiering</span>
            <br />
            <span>311 S Wacker Dr, Suite 1775</span>
            <br />
            <span>Chicago, IL 60606</span>
            <br />
          </p>

          <strong>Acceptable Check Deposits</strong>
          <p>
            <span className={FontWeight.bold}>My IPO does not accept “third party” funding of any kind</span>. This
            means that the name on your bank account must match the name on your My IPO account exactly. For example,
            money transfers from a trust or business bank account (owned by you) to an individual or joint My IPO
            account are considered third-party and <span className={FontWeight.bold}>will not be accepted.</span>
          </p>
          <ul>
            <li>Checks must be payable in U.S. dollars and through a U.S. bank.</li>
            <li>
              Personal checks must be drawn from a bank account in an account owner’s name and must include
              &quot;Jr&quot; or &quot;Sr&quot; if applicable.
            </li>
            <li>
              Checks from joint checking accounts may be deposited into either checking account owner’s My IPO account.
            </li>
            <li>
              Checks from an individual checking account may be deposited into a My IPO joint account if that person is
              one of the account owners.
            </li>
            <li>
              Investment Club checks should be drawn from a checking account in the name of the Investment Club. If a
              member of the Investment Club remits a personal check, the check must be payable to: &quot;Apex Clearing /
              FBO the Investment Club name.&quot; (&quot;FBO&quot; stands for &quot;For the Benefit Of&quot;)
            </li>
          </ul>

          <strong>Unacceptable Check Deposits</strong>

          <ul>
            <li>Cashier’s check</li>
            <li>Money Orders</li>
            <li>Foreign instruments</li>
            <li>Thrift withdrawal orders</li>
            <li>Domestic drafts</li>
            <li>Checks that have been double-endorsed (checks with more than one signature on the back)</li>
            <li>
              Third-party checks not properly made out and endorsed per the rules stated in the &quot;Acceptable
              Deposits&quot; section above
            </li>
            <li>Checks from minors</li>
            <li>Check dated over six months old</li>
            <li>Travelers checks</li>
            <li>Credit card checks</li>
          </ul>
        </>
      </MTooltip>
    </>
  );
};
