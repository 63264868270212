import { countryCodes } from 'src/constants';
import { mask } from 'src/utils';
import * as Yup from 'yup';

export const formatValidationSchemaError = (
  error: Yup.ValidationError | any,
): Record<string, string | string[]> | any => {
  if (error instanceof Yup.ValidationError) {
    return error.inner.reduce((acc: any, error: any) => {
      acc[error.path] = error.errors;

      return acc;
    }, {});
  }

  return error;
};

export const phoneNumberValidator = Yup.array()
  .of(
    Yup.object({
      prefix: Yup.string().required('Phone Number country code is required'),
      value: Yup.string()
        .required('Phone Number is required')
        .test('compare', 'Phone Number is invalid', (value: any, schema: any) => {
          if (isNaN(Number(value))) {
            return false;
          }
          const country = countryCodes.find(anItem => anItem.dialCode === schema?.parent?.prefix);
          const masked = mask({ value, mask: country?.phoneMask ?? '', substitute: '9' });

          return masked?.length == country?.phoneMask?.length;
        }),
      type: Yup.string().required('Phone Number type is required'),
    }),
  )
  .required('At least a valid Phone Number is required');
