import { CheckCircleOutlined } from '@ant-design/icons';
import { Row } from 'antd';
import moment from 'moment';
import { UserDto, CreateOfferWatchDto } from 'src/dtos';
import { MFormTextField } from 'src/lib';
import { OfferDetails } from 'src/models';
import { Images } from 'src/styles';

import { NewOfferWatchDisclaimer } from './NewOfferWatchDisclaimer';
import * as Styles from './NewOfferWatchModal.styles';

export const NewOfferWatchSucceeded = ({
  offer,
  offerWatch,
  user,
}: {
  offer: OfferDetails;
  offerWatch: CreateOfferWatchDto;
  user: UserDto;
}) => {
  const data = [
    {
      label: 'Investor Name',
      value: `${user.firstName} ${user.lastName}`,
    },
    { label: 'Date', value: moment(new Date().toISOString()).format('MM/DD/YYYY') },
    { label: 'First Name', value: user.firstName },
    { label: 'Last Name', value: user.lastName },
    { label: 'Email', value: user.email },
    { label: 'Phone Number', value: user.phoneNumber },
    { label: 'State', value: user.address.state },
    { label: 'Investment Range', value: offerWatch?.investmentRange },
  ];

  return (
    <div>
      <Row className={Styles.title}>
        <CheckCircleOutlined className={Styles.circleOutlinedStyles} />
        <span className={Styles.submissionSucceeded}>Submission Succeeded</span>
      </Row>
      <Row>
        <p className={Styles.subtitle}>
          You will receive updates and notifications when the Offer becomes for actual investment
        </p>
      </Row>
      <Row className={Styles.title}>
        <img className={Styles.offerLogo} src={offer?.logoUrl || Images.DefaultOffering} alt={`${offer.name}_logo`} />
        <span className={Styles.offerName}>{offer.name}</span>
      </Row>
      <Row>
        {data.map(item => (
          <MFormTextField key={item.label} label={item.label} value={item.value} />
        ))}
      </Row>
      <NewOfferWatchDisclaimer />
    </div>
  );
};
