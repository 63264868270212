import { PropsWithChildren } from 'react';

import { Col, Row } from 'antd';

import { MCheckbox, MCheckboxProps } from '../MCheckbox/MCheckbox';

import * as Styles from './MFormCheckbox.styles';

export interface MFormCheckboxProps extends MCheckboxProps {
  label: string;
}

export const MFormCheckbox = ({
  label,
  value,
  checked,
  defaultChecked,
  error,
  onChange,
  disabled,
  children,
  className,
  testId,
}: PropsWithChildren<MFormCheckboxProps>) => {
  return (
    <MCheckbox
      testId={testId}
      value={value}
      defaultChecked={defaultChecked}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      error={error}
      className={className}>
      <Row>
        <Col className={Styles.label}>{label}</Col>
      </Row>
      <Row>{children}</Row>
    </MCheckbox>
  );
};
