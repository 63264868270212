import { MTooltip } from '../MTooltip/MTooltip';

import * as Styles from './MFormItemLabel.style';

export interface MFormItemLabelProps {
  value: string;
  tooltip?: {
    type: 'modal' | 'popover';
    title?: string;
    content: string | JSX.Element;
  };
  weight?: 'light' | 'medium';
  className?: string;
  testId?: string;
}

export const MFormItemLabel = ({ value, tooltip, weight = 'medium', className, testId }: MFormItemLabelProps) => {
  return (
    <div className={`${Styles.container(weight)} ${className ?? ''}`}>
      <div className={Styles.value}>
        <span data-testid={testId}>{value}</span>
        {tooltip && (
          <MTooltip type={tooltip.type} title={tooltip.title}>
            {tooltip.content}
          </MTooltip>
        )}
      </div>
    </div>
  );
};
