import { useEffect, useState } from 'react';

import { Col } from 'antd';
import { Formik, FormikProps } from 'formik';
import { isUndefined } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  doClearCreateAccountHolderDisclosures,
  doClearPatchAccountHolderDisclosures,
  doCreateAccountHolderDisclosures,
  doPatchAccountHolderDisclosures,
} from 'src/actions';
import { AccountHolderStatementDeliveryDto } from 'src/dtos';
import { MFormCheckbox, MFormInput, MFormRadioGroup, MFormSaveButton, MRadioGroupOption } from 'src/lib';
import { Account, AccountHolderStatementDeliveryLabel } from 'src/models';
import { Spacing } from 'src/styles';
import * as Yup from 'yup';

import * as Styles from './Disclosures.styles';

export const upsertAccountHolderDisclosuresValidation = Yup.object().shape({
  statementDelivery: Yup.string().required('Statement Delivery is required'),
  stakeholder: Yup.boolean().default(false),
  industryEmployed: Yup.boolean().default(false),
  tickerSymbol: Yup.string().when('stakeholder', {
    is: true,
    then: Yup.string().trim().required('Company Ticker Symbol is required'),
  }),
  firmName: Yup.string().when('industryEmployed', {
    is: true,
    then: Yup.string().trim().required('Firm Name is required'),
  }),
});

export const STATEMENT_DELIVERY_OPTION_LIST: MRadioGroupOption[] = [
  {
    label: AccountHolderStatementDeliveryLabel.Electronic,
    value: AccountHolderStatementDeliveryDto.Electronic,
  },
  {
    label: AccountHolderStatementDeliveryLabel.Paper,
    value: AccountHolderStatementDeliveryDto.Paper,
    tooltip: {
      type: 'popover',
      content:
        'Trade Confirmations: $2.00 per confirm Monthly Statement: $5.00 per statement Tax Document: $5.00 per document',
    },
  },
];

export interface DisclosuresFormValues {
  statementDelivery?: AccountHolderStatementDeliveryDto;
  stakeholder?: boolean;
  tickerSymbol?: string;
  industryEmployed?: boolean;
  firmName?: string;
}

export interface DisclosuresFormProps {
  account?: Account;
  onCancel?: () => void;
  onSave?: () => void;
}

export const DisclosuresForm = ({ account, onCancel, onSave }: DisclosuresFormProps) => {
  const dispatch = useDispatch();

  const isCreateDisclosuresLoading = useSelector((state: any) =>
    Boolean(state.accountHolders.createDisclosures.__requested),
  );
  const succeededCreateDisclosures = useSelector((state: any) =>
    Boolean(state.accountHolders.createDisclosures.__succeeded),
  );

  const isPatchDisclosuresLoading = useSelector((state: any) =>
    Boolean(state.accountHolders.patchDisclosures.__requested),
  );
  const succeededPatchDisclosures = useSelector((state: any) =>
    Boolean(state.accountHolders.patchDisclosures.__succeeded),
  );

  const [initialValues, setInitialValues] = useState<DisclosuresFormValues>({});
  const [shouldPatch, setShouldPatch] = useState<boolean>(false);

  const isDisclosuresAlreadySaved = () => !isUndefined(account?.primaryAccountHolder?.disclosure?.statementDelivery);

  const mapViewToDto = (values: ReturnType<typeof upsertAccountHolderDisclosuresValidation.cast>) => ({
    statementDelivery: values.statementDelivery,
    stakeholder: values.stakeholder === true ? { tickerSymbol: values.tickerSymbol } : false,
    industryEmployed: values.industryEmployed === true ? { firmName: values.firmName } : false,
  });

  const _onCancel = (form: FormikProps<DisclosuresFormValues>) => {
    form.resetForm();

    if (onCancel) {
      onCancel();
    }
  };

  const _onSave = (form: FormikProps<DisclosuresFormValues>) => {
    form.submitForm();

    if (onSave) {
      onSave();
    }
  };

  useEffect(() => {
    setInitialValues({
      statementDelivery: account?.primaryAccountHolder?.disclosure?.statementDelivery?.value,
      stakeholder: Boolean(account?.primaryAccountHolder?.disclosure?.stakeholder),
      tickerSymbol:
        account?.primaryAccountHolder?.disclosure?.stakeholder === false
          ? ''
          : account?.primaryAccountHolder?.disclosure?.stakeholder?.tickerSymbol,
      industryEmployed: Boolean(account?.primaryAccountHolder?.disclosure?.industryEmployed),
      firmName:
        account?.primaryAccountHolder?.disclosure?.industryEmployed === false
          ? ''
          : account?.primaryAccountHolder?.disclosure?.industryEmployed?.firmName,
    });
  }, [account?.primaryAccountHolder]);

  useEffect(() => {
    if (isDisclosuresAlreadySaved()) {
      setShouldPatch(true);
    }
  }, [account?.primaryAccountHolder]);

  useEffect(() => {
    if (succeededCreateDisclosures || succeededPatchDisclosures) {
      if (onCancel) {
        onCancel();
      }
    }
  }, [succeededCreateDisclosures, succeededPatchDisclosures]);

  useEffect(() => {
    return () => {
      dispatch(doClearCreateAccountHolderDisclosures());
      dispatch(doClearPatchAccountHolderDisclosures());
    };
  }, []);

  return (
    <Formik
      enableReinitialize
      validateOnChange
      validateOnBlur
      onSubmit={values => {
        const cValues = upsertAccountHolderDisclosuresValidation.cast(values);
        // TODO: sanitize the dto values
        const dto = mapViewToDto(cValues);

        if (shouldPatch) {
          dispatch(doPatchAccountHolderDisclosures(dto));

          return;
        }

        dispatch(doCreateAccountHolderDisclosures(dto));
      }}
      initialValues={initialValues}
      validationSchema={upsertAccountHolderDisclosuresValidation}>
      {form => {
        return (
          <>
            <Col span={24}>
              <MFormRadioGroup
                testId={'account-statement-delivery'}
                label='Statement Delivery'
                align='vertical'
                value={form.values.statementDelivery}
                defaultValue={account?.primaryAccountHolder?.disclosure?.statementDelivery?.value}
                options={STATEMENT_DELIVERY_OPTION_LIST}
                error={form.errors.statementDelivery}
                onChange={value => {
                  form.setFieldValue('statementDelivery', value);
                }}
              />
            </Col>

            <Col span={24} className={Styles.titleContainer}>
              <p className={Styles.title}>Individual Representations</p>
              <p className={Styles.subtitle}>(Please attest as to whether any of the following apply to you.)</p>
            </Col>

            <Col span={24} className={`$${Spacing.mt4} ${form.values.stakeholder ? '' : Spacing.mb16}`}>
              <MFormCheckbox
                testId={'account-stakeholder'}
                label='I am a stakeholder in a publicly traded company'
                checked={form.values.stakeholder}
                defaultChecked={Boolean(account?.primaryAccountHolder?.disclosure?.stakeholder)}
                onChange={value => {
                  form.setFieldValue('stakeholder', value.checked);
                }}
                error={form.errors.stakeholder}
              />
            </Col>
            {form.values.stakeholder && (
              <Col span={24}>
                <MFormInput
                  testId={'account-stakeholder-company-ticker-symbol'}
                  label='Company Ticker Symbol(s)'
                  weight='light'
                  placeholder='Enter'
                  defaultValue={
                    account?.primaryAccountHolder?.disclosure?.stakeholder === false
                      ? ''
                      : account?.primaryAccountHolder?.disclosure?.stakeholder?.tickerSymbol
                  }
                  value={form.values.tickerSymbol}
                  onChange={value => form.setFieldValue('tickerSymbol', value)}
                  error={form.errors.tickerSymbol}
                />
              </Col>
            )}

            <Col span={24} className={Spacing.mt4}>
              <MFormCheckbox
                testId={'account-finra'}
                label='Are you employed by or a member of a registered broker-dealer, securities or futures exchange, or securities industry regulatory body (e.g., FINRA, NFA)?'
                checked={form.values.industryEmployed}
                defaultChecked={Boolean(account?.primaryAccountHolder?.disclosure?.industryEmployed)}
                onChange={value => {
                  form.setFieldValue('industryEmployed', value.checked);
                }}></MFormCheckbox>
            </Col>
            {form.values.industryEmployed && (
              <Col span={24}>
                <MFormInput
                  testId={'account-finra-firm-name'}
                  label='Firm Name'
                  weight='light'
                  placeholder='Enter'
                  defaultValue={
                    account?.primaryAccountHolder?.disclosure?.industryEmployed === false
                      ? ''
                      : account?.primaryAccountHolder?.disclosure?.industryEmployed?.firmName
                  }
                  value={form.values.firmName}
                  onChange={value => form.setFieldValue('firmName', value)}
                  error={form.errors.firmName}
                />
              </Col>
            )}

            <Col span={24}>
              <MFormSaveButton<DisclosuresFormValues>
                loading={isCreateDisclosuresLoading || isPatchDisclosuresLoading}
                onCancel={_onCancel}
                onSave={_onSave}
                isEditMode={shouldPatch}
                form={form}
                testId={'disclosures'}
              />
            </Col>
          </>
        );
      }}
    </Formik>
  );
};
