import { useEffect, useState } from 'react';

import { Col, Row, Skeleton } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { ColumnsType } from 'antd/lib/table';
import _ from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';
import { FilterBarConstant } from 'src/constants';
import { OfferOrderPaymentTypeDto, OfferOrderStatusDto } from 'src/dtos';
import { useAccountSelector } from 'src/hooks';
import {
  CancelOfferOrderButton,
  CancelOrderButton,
  CancelTransferButton,
  ConfirmActionModal,
  FilterBar,
  FilterBarCheckboxGroup,
  FilterBarCheckboxGroupOption,
  FilterBarSample,
  LinkText,
  MTable,
  OfferOrderStatusTag,
  OfferOrderTypeCol,
  OrderStatusTag,
  OrderTypeCol,
  TransferStatusTag,
  TransferTypeCol,
  SecuritiesHeldAndFundedOutsideDisclaimerModal,
  ExternalOfferDisclaimerModal,
} from 'src/lib';
import { MButton } from 'src/lib/FormComponents/MButton/MButton';
import CurrencyField from 'src/lib/Miscellaneous/FormattedFields/CurrencyField/CurrencyField';
import { OfferDetails, OfferOrder, OfferOrderStatusLabel, Order, OrderStatusLabel } from 'src/models';
import { Transfer, TransferStatusLabel } from 'src/models/transfers.models';
import { ScreenBreakpoint } from 'src/styles';
import {
  getOfferOrderStatusTagColor,
  getOrderStatusTagColor,
  getTransferSearch,
  getTransferStatusTagColor,
  onFixedTimelineFilterSelected,
} from 'src/utils';

import { MAIN_PATH } from '../..';
import {
  cancelDeposit,
  cancelOrdersRequested,
  cancelWithdraw,
  doClearOfferDetails,
  getOfferDetails,
  getTransfers,
  doUpdateOfferOrder,
} from '../../../../actions';
import { useOfferOrders } from '../../../../hooks/useOfferOrders';
import { useOrders } from '../../../../hooks/useOrders';
import { FixedTimelineFilterValueType } from '../../../../lib/Filters/TimelineFilter/TimelineFilter';

import * as Styles from './History.styles';
import { isOrder, isTransfer } from './history.utils';
import { HistoryViewDetailsModal } from './HistoryViewDetailsModal';

export const HISTORY_PATH = 'history';

interface HistoryViewModel {
  key: React.Key;
  date: string;
  type: JSX.Element;
  category: FilterBarConstant.ShowFilterCategory;
  amount: JSX.Element;
  status: string;
  statusCategory: FilterBarConstant.ByFilterCategory;
  cancel: JSX.Element | null;
  view: JSX.Element;
}

const historyListCols: ColumnsType<Omit<HistoryViewModel, 'status' | 'category' | 'statusCategory'>> = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    width: '20%',
    render: value => <LinkText value={value} />,
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    width: '35%',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    width: '15%',
    align: 'right',
  },
  {
    key: 'cancel',
    dataIndex: 'cancel',
    width: '15%',
  },
  {
    key: 'view',
    dataIndex: 'view',
    width: '15%',
  },
];

const History = () => {
  const params = useLocation().search;
  const searchParams = new URLSearchParams(params);
  const filterBy = searchParams?.get('filterBy');
  const show = searchParams?.get('show');
  const defaultValues: FilterBarSample = {
    sortBy: FilterBarConstant.SortByCategory.Newest,
    timeline: FixedTimelineFilterValueType.THIS_YEAR,
    customTimeline: undefined,
    filterBy: [],
    show: [],
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orderList, isOrderListLoading } = useOrders();
  const { offerOrderList, isOfferOrderListLoading } = useOfferOrders();
  const [historyList, setHistoryList] = useState<HistoryViewModel[]>([]);
  const [filteredHistory, setFilteredHistory] = useState<HistoryViewModel[]>([]);
  const [filters, setFilters] = useState<FilterBarSample>({
    ...defaultValues,
    filterBy: filterBy ? [...JSON.parse(filterBy)] : [],
    show: show ? [...JSON.parse(show)] : [],
  });

  const [isSecuritiesPurchasedByCardModalOpen, setIsSecuritiesPurchasedByCardModalOpen] = useState<boolean>(false);
  const [isExternalOfferDisclaimerOpen, setIsExternalOfferDisclaimerOpen] = useState<boolean>(false);

  const transferList: Transfer[] | undefined = useSelector((state: any) => state.cashiering.transfers?.data);

  const isLoadingBankTransactions = useSelector((state: any) => state.cashiering.transfers?.__requested);

  const offerDetails: OfferDetails | undefined = useSelector((state: any) => state.offers.offerDetails?.data);
  const isOfferDetailsLoading: boolean = useSelector((state: any) => state.offers.offerDetails?.isLoading);

  const [isCancelConfirmationModalOpen, setIsCancelConfirmationModalOpen] = useState<boolean>(false);
  const [isViewHistoryDetailsModalOpen, setIsViewHistoryDetailsModalOpen] = useState<boolean>(false);
  const [selectedHistoryItem, setSelectedHistoryItem] = useState<Transfer | OfferOrder | Order | null>(null);

  const { account, isAccountLoading } = useAccountSelector();

  const isMobile = useMediaQuery({ query: `(max-width: ${ScreenBreakpoint.mobile.max})` });

  const onViewMore = (offerOrder: OfferOrder) => {
    if (offerOrder.paymentType.isCard) {
      setIsSecuritiesPurchasedByCardModalOpen(true);

      return;
    }

    if (offerDetails?.id !== offerOrder.offerId) {
      dispatch(getOfferDetails(offerOrder.offerId));
    }
    setIsExternalOfferDisclaimerOpen(true);
  };

  const onCancel = (value: Transfer | OfferOrder | Order) => {
    setSelectedHistoryItem(value);
    setIsCancelConfirmationModalOpen(true);
  };

  const onViewDetails = (value: Transfer | OfferOrder | Order) => {
    setSelectedHistoryItem(value);
    setIsViewHistoryDetailsModalOpen(true);
  };

  const cancelTransfer = (transfer: Transfer) => {
    if (transfer.direction.isDeposit) {
      dispatch(cancelDeposit(transfer.id, account?.accountId));

      return;
    }
    dispatch(cancelWithdraw(transfer.id, account?.accountId));

    return;
  };

  const onConfirmCancel = (selectedItem: Transfer | OfferOrder | Order) => {
    if (isTransfer(selectedItem)) {
      cancelTransfer(selectedItem);

      return;
    }

    if (isOrder(selectedItem)) {
      dispatch(cancelOrdersRequested({ id: selectedItem.id, accountId: account?.accountId }));

      return;
    }

    dispatch(
      doUpdateOfferOrder({
        params: {
          id: selectedItem.id,
        },
        body: {
          status: OfferOrderStatusDto.PendingFirmCancellation,
          useEmailNotifier: true,
        },
      }),
    );
  };

  const mapTransferListToViewModelList = (): HistoryViewModel[] =>
    Array.isArray(transferList)
      ? transferList.map((transfer: Transfer) => ({
          key: transfer.id,
          date: moment(transfer.updatedAt).local().utcOffset(7).utcOffset(0, true).format(), // NOTE: transfer timestampe might not have tz, use local tz minus 7 by defaul
          amount: (
            <CurrencyField
              value={transfer.amount}
              prefix={`${transfer.direction.isWithdrawal ? '-' : ''}$`}
              className={Styles.amountValue({ isNegative: transfer.direction.isWithdrawal })}
            />
          ),
          category: transfer.direction.isDeposit
            ? FilterBarConstant.ShowFilterCategory.DepositTransfer
            : FilterBarConstant.ShowFilterCategory.WithdrawalTransfer,
          statusCategory: FilterBarConstant.ByFilterCategory.MoneyTransfer,
          status: transfer.status.label,
          type: <TransferTypeCol value={transfer} testId={'column-type-id'} />,
          cancel: <CancelTransferButton value={transfer} onClick={onCancel} />,
          view: (
            <MButton type='tertiary' className={Styles.viewDetailsButton} onClick={() => onViewDetails(transfer)}>
              View Details
            </MButton>
          ),
        }))
      : [];

  const mapOfferOrderListToViewModelList = (): HistoryViewModel[] =>
    offerOrderList.map((offerOrder: OfferOrder) => ({
      key: offerOrder.key,
      date: offerOrder.updatedAt ?? offerOrder.createdAt,
      amount: <CurrencyField value={-offerOrder.value} className={Styles.amountValue({ isNegative: true })} />,
      category: FilterBarConstant.ShowFilterCategory.OfferOrder,
      statusCategory: FilterBarConstant.ByFilterCategory.OfferOrder,
      status: offerOrder.status.label,
      type: (
        <OfferOrderTypeCol value={offerOrder} onViewMore={() => onViewMore(offerOrder)} testId={'column-type-id'} />
      ),
      cancel: <CancelOfferOrderButton value={offerOrder} onClick={onCancel} />,
      view: (
        <MButton type='tertiary' className={Styles.viewDetailsButton} onClick={() => onViewDetails(offerOrder)}>
          View Details
        </MButton>
      ),
    }));

  const mapOrderListToViewModelList = (): HistoryViewModel[] =>
    orderList.map((order: Order) => ({
      key: order.id,
      date: order.updatedAt,
      amount: (
        <CurrencyField value={order.totalCost} className={Styles.amountValue({ isNegative: order.action.isBuy })} />
      ),
      category: order.action.isBuy
        ? FilterBarConstant.ShowFilterCategory.BuyOrder
        : FilterBarConstant.ShowFilterCategory.SellOrder,
      statusCategory: FilterBarConstant.ByFilterCategory.Order,
      status: order.status.label,
      type: <OrderTypeCol value={order} testId={'column-type-id'} />,
      cancel: <CancelOrderButton value={order} onClick={onCancel} />,
      view: (
        <MButton type='tertiary' className={Styles.viewDetailsButton} onClick={() => onViewDetails(order)}>
          View Details
        </MButton>
      ),
    }));

  const mapTransferStatusLabelToStatusFilterGroup = (): FilterBarCheckboxGroup[] => {
    const options: FilterBarCheckboxGroupOption[] = Object.values(TransferStatusLabel).map(value => ({
      label: <TransferStatusTag value={value} />,
      value: `${value}_${FilterBarConstant.ByFilterCategory.MoneyTransfer}`,
      tag: {
        text: value,
        ...getTransferStatusTagColor(value),
      },
    }));

    return [{ title: 'Money Transaction(s)', options }];
  };

  const mapOfferOrderStatusToStatusFilterGroup = (): FilterBarCheckboxGroup[] => {
    const options: FilterBarCheckboxGroupOption[] = Object.values(OfferOrderStatusLabel).map(value => ({
      label: <OfferOrderStatusTag value={value} />,
      value: `${value}_${FilterBarConstant.ByFilterCategory.OfferOrder}`,
      tag: {
        text: value,
        ...getOfferOrderStatusTagColor(value),
      },
    }));

    return [{ title: 'Offer Order(s)', options }];
  };

  const mapOrderStatusToStatusFilterGroup = (): FilterBarCheckboxGroup[] => {
    const options: FilterBarCheckboxGroupOption[] = Object.values(OrderStatusLabel).map(value => ({
      label: <OrderStatusTag value={value} />,
      value: `${value}_${FilterBarConstant.ByFilterCategory.Order}`,
      tag: {
        text: value,
        ...getOrderStatusTagColor(value),
      },
    }));

    return [{ title: 'Security Order(s)', options }];
  };

  const getStatusFilterGroups = () => {
    let result = mapTransferStatusLabelToStatusFilterGroup();
    result = result.concat(mapOfferOrderStatusToStatusFilterGroup());
    result = result.concat(mapOrderStatusToStatusFilterGroup());

    return result;
  };

  const getShowFilterGroup = (): FilterBarCheckboxGroup[] => {
    const options = [
      { label: 'Cash Deposit(s)', value: FilterBarConstant.ShowFilterCategory.DepositTransfer },
      { label: 'Cash Withdrawal(s)', value: FilterBarConstant.ShowFilterCategory.WithdrawalTransfer },
      { label: 'Offer Order(s)', value: FilterBarConstant.ShowFilterCategory.OfferOrder },
      { label: 'Security (Buy)', value: FilterBarConstant.ShowFilterCategory.BuyOrder },
      { label: 'Security (Sell)', value: FilterBarConstant.ShowFilterCategory.SellOrder },
    ];

    return [{ title: 'Show', options }];
  };

  const getActionConfirmModalType = (value: Transfer | OfferOrder | Order) => {
    let type = 'offer order';

    if (isTransfer(value)) {
      type = 'transfer';
    }

    if (isOrder(value)) {
      type = 'order';
    }

    return 'Are you sure you want to cancel this ' + type + '?';
  };

  const updateHistoryDataList = () => {
    const transferList: HistoryViewModel[] = mapTransferListToViewModelList();
    const orderHistoryList: HistoryViewModel[] = mapOrderListToViewModelList();
    const offerOrderHistoryList: HistoryViewModel[] = mapOfferOrderListToViewModelList();
    const allRecords = transferList.concat(orderHistoryList, offerOrderHistoryList);
    const orderedAllHistoryList = _.orderBy(allRecords, ['date'], ['desc']);
    setHistoryList(orderedAllHistoryList);
  };

  const onApproveFilters = (newFilters: FilterBarSample) => {
    setFilters({ ...newFilters });

    if (JSON.stringify(filters) !== JSON.stringify(newFilters)) {
      navigate(`/${MAIN_PATH}/${HISTORY_PATH}`);
    }
  };

  const filterByStatus = (list: HistoryViewModel[]) => {
    const filtersAndCategories = filters.filterBy?.map((filter: CheckboxValueType) => {
      const filterValue = filter.toString();
      const [value, category] = filterValue.split('_');

      return { value, category: category as FilterBarConstant.ByFilterCategory };
    });

    return list.filter((history: HistoryViewModel) => {
      return (
        filtersAndCategories?.some(({ value, category }) => {
          return category === history.statusCategory && value === history.status;
        }) ||
        !filtersAndCategories?.some(({ category }) => {
          return category === history.statusCategory;
        })
      );
    });
  };

  const applyFilters = (): HistoryViewModel[] => {
    let filteredHistoryList = [...historyList];

    if (filters.show && filters.show.length > 0) {
      filteredHistoryList = filteredHistoryList.filter((history: HistoryViewModel) =>
        filters.show?.includes(history.category),
      );
    }

    if (filters.filterBy && filters.filterBy.length > 0) filteredHistoryList = filterByStatus(filteredHistoryList);

    if (filters.timeline !== undefined) {
      const timelineDate = onFixedTimelineFilterSelected(filters.timeline);
      filteredHistoryList = filteredHistoryList.filter(
        (history: HistoryViewModel) =>
          moment(history.date).isSameOrBefore(timelineDate.endDate) &&
          moment(history.date).isSameOrAfter(timelineDate.startDate),
      );
    }

    if (filters.customTimeline !== undefined) {
      const [startDate, endDate] = filters.customTimeline;
      filteredHistoryList = filteredHistoryList.filter(
        (history: HistoryViewModel) =>
          moment(history.date).isSameOrBefore(endDate) && moment(history.date).isSameOrAfter(startDate),
      );
    }

    filteredHistoryList = _.orderBy(
      filteredHistoryList,
      ['date'],
      [filters.sortBy === FilterBarConstant.SortByCategory.Newest ? 'desc' : 'asc'],
    );

    return filteredHistoryList;
  };

  useEffect(() => {
    updateHistoryDataList();
  }, [orderList, offerOrderList, transferList, offerDetails]);

  useEffect(() => {
    const historyNewList = applyFilters();
    setFilteredHistory(
      historyNewList.map((item: any) => ({
        ...item,
        date: moment(item.date).format('MM/DD/yyyy'),
      })),
    );
  }, [filters, historyList]);

  useEffect(() => {
    if (account?.accountId && !isLoadingBankTransactions)
      dispatch(getTransfers(account.accountId, getTransferSearch(filters)));
  }, [filters.timeline, filters.customTimeline, account?.accountId]);

  useEffect(() => {
    return () => {
      dispatch(doClearOfferDetails());
    };
  }, []);

  return (
    <Skeleton loading={isAccountLoading}>
      <Row gutter={[, 16]}>
        <Col span={24}>
          <Row justify='center' align='middle' className={Styles.headerContainer}>
            <Col span={24} sm={18} xs={18} className={Styles.titleContainer}>
              History of Transactions
            </Col>
            <Col span={24} sm={6} xs={6} md={24}>
              <FilterBar
                showFilter={getShowFilterGroup()}
                byFilter={getStatusFilterGroups()}
                onApprove={onApproveFilters}
                defaultValue={defaultValues}
                initValue={filters}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24} className={Styles.tableContainer}>
          <MTable
            testId={'items-table'}
            columns={historyListCols}
            data={filteredHistory}
            pagination={{ position: ['bottomRight'] }}
            scroll={isMobile ? { x: 180 } : {}}
            loading={isOrderListLoading || isLoadingBankTransactions || isOfferOrderListLoading}
          />
        </Col>
      </Row>
      <SecuritiesHeldAndFundedOutsideDisclaimerModal
        type={OfferOrderPaymentTypeDto.Card}
        isVisible={isSecuritiesPurchasedByCardModalOpen}
        onClose={() => setIsSecuritiesPurchasedByCardModalOpen(false)}
        onOK={() => setIsSecuritiesPurchasedByCardModalOpen(false)}
      />
      <ExternalOfferDisclaimerModal
        isOpen={isExternalOfferDisclaimerOpen}
        isLoading={isOfferDetailsLoading}
        disclaimer={offerDetails?.externalDetails?.disclaimer}
        onClose={() => {
          setIsExternalOfferDisclaimerOpen(false);
        }}
      />
      {selectedHistoryItem && (
        <HistoryViewDetailsModal
          value={selectedHistoryItem}
          isOpen={isViewHistoryDetailsModalOpen}
          onClose={() => {
            setIsViewHistoryDetailsModalOpen(false);
            setSelectedHistoryItem(null);
          }}
        />
      )}
      {selectedHistoryItem && (
        <ConfirmActionModal
          title='Cancel Confirmation'
          message={getActionConfirmModalType(selectedHistoryItem)}
          isOpen={isCancelConfirmationModalOpen}
          onCancel={() => {
            setIsCancelConfirmationModalOpen(false);
            setSelectedHistoryItem(null);
          }}
          onOk={() => {
            onConfirmCancel(selectedHistoryItem);
            setIsCancelConfirmationModalOpen(false);
            setSelectedHistoryItem(null);
          }}
        />
      )}
    </Skeleton>
  );
};

export default History;
