import { useEffect, useRef, useState } from 'react';

import { Col } from 'antd';
import { Formik, FormikProps } from 'formik';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  doClearCreateAccountHolderPersonalInformation,
  doClearPatchAccountHolderPersonalInformation,
  doCreateAccountHolderPersonalInformation,
  doPatchAccountHolderPersonalInformation,
} from 'src/actions';
import { AccountHolderMaritalStatusDto } from 'src/dtos';
import {
  DateOfBirthPicker,
  IdentityVerificationInstructions,
  MFormInput,
  MFormSaveButton,
  MFormSelect,
  MModal,
  MTooltip,
  SsidInput,
} from 'src/lib';
import { Account, AccountHolderMaritalStatusLabel } from 'src/models';
import { Font, Spacing } from 'src/styles';

import {
  createAccountHolderPersonalInformationValidationSchema,
  patchAccountHolderPersonalInformationValidationSchema,
} from './validations';

export const MARITAL_STATUS_OPTION_LIST = [
  { value: AccountHolderMaritalStatusDto.Single, label: AccountHolderMaritalStatusLabel.Single },
  { value: AccountHolderMaritalStatusDto.Married, label: AccountHolderMaritalStatusLabel.Married },
  { value: AccountHolderMaritalStatusDto.Divorced, label: AccountHolderMaritalStatusLabel.Divorced },
  { value: AccountHolderMaritalStatusDto.Widowed, label: AccountHolderMaritalStatusLabel.Widowed },
];

interface AccountHolderPersonalInformationFormValues {
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  maritalStatus?: string;
  numberOfDependents?: string;
  taxId?: string;
  middleName?: string;
  suffix?: string;
}

export interface AccountHolderPersonalInformationFormProps {
  account?: Account;
  onCancel?: () => void;
  onSave?: () => void;
}

export const AccountHolderPersonalInformationForm = ({
  account,
  onCancel,
  onSave,
}: AccountHolderPersonalInformationFormProps) => {
  const dispatch = useDispatch();

  const isCreatePersonalInformationLoading = useSelector((state: any) =>
    Boolean(state.accountHolders.createPersonalInformation?.__requested),
  );
  const succeededCreatePersonalInformation = useSelector(
    (state: any) => state.accountHolders.createPersonalInformation?.__succeeded,
  );

  const isPatchPersonalInformationLoading = useSelector((state: any) =>
    Boolean(state.accountHolders.patchPersonalInformation.__requested),
  );
  const succeededPatchPersonalInformation = useSelector(
    (state: any) => state.accountHolders.patchPersonalInformation?.__succeeded,
  );

  const [initialValues, setInitialValues] = useState<AccountHolderPersonalInformationFormValues>({});
  const [shouldPatch, setShouldPatch] = useState<boolean>(false);
  const formRef = useRef<FormikProps<AccountHolderPersonalInformationFormValues> | null>(null);

  const [isConfirmCreateModalVisible, setIsConfirmCreateModalVisible] = useState<boolean>(false);

  const isUpsertPersonalInformationLoading = isPatchPersonalInformationLoading || isCreatePersonalInformationLoading;

  const isPersonalInformationAlreadySaved = () => Boolean(account?.primaryAccountHolder?.dateOfBirth);

  const _onCancel = (form: FormikProps<AccountHolderPersonalInformationFormValues>) => {
    form.resetForm();

    if (onCancel) {
      onCancel();
    }
  };

  const _onSave = (form: FormikProps<AccountHolderPersonalInformationFormValues>) => {
    form.submitForm();

    if (onSave) {
      onSave();
    }
  };

  useEffect(() => {
    setInitialValues({
      firstName: account?.primaryAccountHolder?.firstName,
      lastName: account?.primaryAccountHolder?.lastName,
      dateOfBirth: account?.primaryAccountHolder?.dateOfBirth,
      middleName: account?.primaryAccountHolder?.middleName,
      suffix: account?.primaryAccountHolder?.suffix,
      numberOfDependents: account?.primaryAccountHolder?.numberOfDependents?.toString(),
      taxId: account?.primaryAccountHolder?.taxId,
      maritalStatus: account?.primaryAccountHolder?.maritalStatus?.value,
    });
  }, [account?.primaryAccountHolder]);

  useEffect(() => {
    if (isPersonalInformationAlreadySaved()) {
      setShouldPatch(true);
    }
  }, [account?.primaryAccountHolder]);

  useEffect(() => {
    if (succeededCreatePersonalInformation || succeededPatchPersonalInformation) {
      if (onCancel) {
        onCancel();
      }
    }
  }, [succeededCreatePersonalInformation, succeededPatchPersonalInformation]);

  useEffect(() => {
    return () => {
      dispatch(doClearCreateAccountHolderPersonalInformation());
      dispatch(doClearPatchAccountHolderPersonalInformation());
    };
  }, []);

  return (
    <>
      <Formik
        enableReinitialize
        validateOnChange
        validateOnBlur
        innerRef={ref => {
          formRef.current = ref;
        }}
        onSubmit={values => {
          if (shouldPatch) {
            const cValues = patchAccountHolderPersonalInformationValidationSchema.cast(values);
            // TOOD: sanitize values (remove values which are not changed)
            dispatch(
              doPatchAccountHolderPersonalInformation({
                numberOfDependents: cValues.numberOfDependents,
                maritalStatus: cValues.maritalStatus,
              }),
            );

            return;
          }
          setIsConfirmCreateModalVisible(true);
        }}
        initialValues={initialValues}
        validationSchema={
          shouldPatch
            ? patchAccountHolderPersonalInformationValidationSchema
            : createAccountHolderPersonalInformationValidationSchema
        }>
        {form => {
          return (
            <>
              <Col span={24} className={Spacing.mb12}>
                <MTooltip
                  icon='info'
                  title='Identity Verification Instructions'
                  label='Please enter your name as shown on a goverment-issued ID'
                  type='modal'
                  className={Font.md}>
                  <IdentityVerificationInstructions />
                </MTooltip>
              </Col>
              <Col span={24}>
                <MFormInput
                  testId={'account-first-name'}
                  label='First Name'
                  value={form.values.firstName}
                  defaultValue={initialValues.firstName}
                  error={form.errors.firstName}
                  disabled
                  onChange={value => {
                    form.setFieldValue('firstName', value);
                  }}
                />
              </Col>

              <Col span={24}>
                <MFormInput
                  testId={'account-middle-name'}
                  label='Middle Initial or Middle Name'
                  placeholder={shouldPatch ? undefined : 'Enter'}
                  value={form.values.middleName}
                  defaultValue={initialValues.middleName}
                  disabled={shouldPatch ? true : !isEmpty(account?.primaryAccountHolder?.middleName)}
                  error={form.errors.middleName}
                  onChange={value => {
                    form.setFieldValue('middleName', value);
                  }}
                />
              </Col>

              <Col span={24}>
                <MFormInput
                  testId={'account-last-name'}
                  label='Last Name'
                  placeholder='Enter'
                  disabled
                  value={form.values.lastName}
                  defaultValue={initialValues.lastName}
                  error={form.errors.lastName}
                  onChange={value => {
                    form.setFieldValue('lastName', value.trim);
                  }}
                />
              </Col>

              <Col span={24}>
                <MFormInput
                  testId={'account-suffix'}
                  label='Suffix (optional)'
                  placeholder={shouldPatch ? undefined : 'Enter'}
                  value={form.values.suffix}
                  defaultValue={initialValues.suffix}
                  disabled={shouldPatch ? true : !isEmpty(account?.primaryAccountHolder?.suffix)}
                  error={form.errors.suffix}
                  onChange={value => {
                    form.setFieldValue('suffix', value);
                  }}
                />
              </Col>

              <Col span={24}>
                <SsidInput
                  testId={'account-ssn'}
                  value={form.values.taxId}
                  defaultValue={initialValues.taxId}
                  disabled={Boolean(account?.primaryAccountHolder?.taxId)}
                  error={form.errors.taxId}
                  onChange={value => {
                    form.setFieldValue('taxId', value);
                  }}
                />
              </Col>

              <Col span={24}>
                <DateOfBirthPicker
                  testId={'account-dob'}
                  defaultValue={account?.primaryAccountHolder?.dateOfBirth}
                  disabled={Boolean(account?.primaryAccountHolder?.dateOfBirth)}
                  error={form.errors.dateOfBirth}
                  onChange={value => {
                    form.setFieldValue('dateOfBirth', value);
                  }}
                />
              </Col>

              <Col span={24}>
                <MFormSelect
                  testId={'account-marital-status'}
                  label='Marital Status'
                  options={MARITAL_STATUS_OPTION_LIST}
                  defaultValue={account?.primaryAccountHolder?.maritalStatus?.value}
                  error={form.errors.maritalStatus}
                  onChange={value => {
                    form.setFieldValue('maritalStatus', value);
                  }}
                />
              </Col>

              <Col span={24}>
                <MFormInput
                  testId={'account-number-of-dependents'}
                  label='Number of Dependents'
                  placeholder='Enter'
                  value={form.values.numberOfDependents}
                  defaultValue={account?.primaryAccountHolder?.numberOfDependents?.toString()}
                  error={form.errors.numberOfDependents}
                  onChange={value => {
                    form.setFieldValue('numberOfDependents', value);
                  }}
                />
              </Col>

              <Col span={24}>
                <MFormSaveButton<AccountHolderPersonalInformationFormValues>
                  loading={isUpsertPersonalInformationLoading}
                  onCancel={_onCancel}
                  onSave={_onSave}
                  isEditMode={shouldPatch}
                  form={form}
                  testId={'personal-information'}
                />
              </Col>
            </>
          );
        }}
      </Formik>
      <MModal
        title='Saving Personal Information'
        customWidth={520}
        visible={isConfirmCreateModalVisible}
        onClose={() => setIsConfirmCreateModalVisible(false)}
        primaryButtonText='Continue'
        secondaryButtonText='Edit'
        onPrimaryButtonClick={() => {
          setIsConfirmCreateModalVisible(false);
          dispatch(
            doCreateAccountHolderPersonalInformation({
              middleName: account?.primaryAccountHolder?.middleName ? undefined : formRef.current?.values?.middleName,
              suffix: account?.primaryAccountHolder?.suffix ? undefined : formRef.current?.values?.suffix,
              taxId: formRef.current?.values?.taxId,
              dateOfBirth: formRef.current?.values?.dateOfBirth,
              maritalStatus: formRef.current?.values?.maritalStatus,
              numberOfDependents: formRef.current?.values?.numberOfDependents,
            }),
          );
        }}
        onSecondaryButtonClick={() => setIsConfirmCreateModalVisible(false)}>
        <>
          Once your personal information is saved you will not be able to edit your social security number, date of
          birth, middle initial or middle name and suffix. Please review your personal information to make sure
          everything is accurate.
          <br />
          <br />
          If the information is correct, please click “Continue”.
        </>
      </MModal>
    </>
  );
};
