import { useEffect, useState } from 'react';

import { Col, Row, Tooltip } from 'antd';
import { isEmpty, isUndefined } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router';
import { clearToastMessages } from 'src/actions';
import { useAccountSelector } from 'src/hooks';
import { ScreenBreakpoint } from 'src/styles';

import { MyAccountLayout } from '../../../lib/Layout/MyAccountLayout/MyAccountLayout';
import { MyAccountSidebarMainMenuItemKey } from '../../../lib/Layout/MyAccountLayout/MyAccountSidebar';

import { AccountHolderMailingAddress } from './AccountHolderMailingAddress';
import { AccountHolderPersonalInformation } from './AccountHolderPersonalInformation';
import { AccountHolderPhysicalAddress } from './AccountHolderPhysicalAddress';
import * as Styles from './PersonalInformation.styles';

export const PersonalInformation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isCreatePersonalInformationSucceess = useSelector((state: any) =>
    Boolean(state.accountHolders.createPersonalInformation.__succeeded),
  );
  const isPatchPersonalInformationSuccess: boolean = useSelector((state: any) =>
    Boolean(state.accountHolders.patchPersonalInformation.__succeeded),
  );
  const isCreatePhysicalAddressSuccess: boolean = useSelector((state: any) =>
    Boolean(state.accountHolders.createPhysicalAddress.__succeeded),
  );
  const isPatchPhysicalAddressSuccess: boolean = useSelector((state: any) =>
    Boolean(state.accountHolders.patchPhysicalAddress.__succeeded),
  );
  const isCreateMailingAddressSuccess: boolean = useSelector((state: any) =>
    Boolean(state.accountHolders.createMailingAddress.__succeeded),
  );
  const isPatchMailingAddressSuccess: boolean = useSelector((state: any) =>
    Boolean(state.accountHolders.patchMailingAddress.__succeeded),
  );

  const [showTooltip, setShowTooltip] = useState<NodeJS.Timeout>();

  const { account } = useAccountSelector();

  const isMobile = useMediaQuery({ query: `(max-width: ${ScreenBreakpoint.mobile.max})` });

  const isPersonalInformationComplete = () => !isUndefined(account?.primaryAccountHolder?.dateOfBirth);
  const isPhysicalAddressComplete = () => !isEmpty(account?.primaryAccountHolder?.physicalAddress);
  const isMailingAddressComplete = () => !isEmpty(account?.primaryAccountHolder?.mailingAddress);

  const hasAnyCreateRequest = () =>
    isCreatePersonalInformationSucceess || isCreatePhysicalAddressSuccess || isCreateMailingAddressSuccess;
  const hasAnyPatchRequest = () =>
    isPatchPersonalInformationSuccess || isPatchPhysicalAddressSuccess || isPatchMailingAddressSuccess;
  const hasAnyUpsertRequest = () => hasAnyCreateRequest() || hasAnyPatchRequest();

  const shouldSendAnalyticsEvent = () =>
    !account?.accountId &&
    hasAnyCreateRequest() &&
    isPersonalInformationComplete() &&
    isPhysicalAddressComplete() &&
    isMailingAddressComplete();

  const shouldRedirectToTrustedContact = () =>
    !account?.accountId &&
    hasAnyUpsertRequest() &&
    isPersonalInformationComplete() &&
    isPhysicalAddressComplete() &&
    isMailingAddressComplete();

  const copyToClipboard = () => {
    clearTimeout(showTooltip);
    navigator.clipboard.writeText(account?.accountNumber ?? '');
    const timeout = setTimeout(() => setShowTooltip(undefined), 2000);
    setShowTooltip(timeout);
  };

  useEffect(() => {
    if (shouldSendAnalyticsEvent()) {
      window.gtag('event', 'account_personal_information_complete');
    }
  }, [isCreatePersonalInformationSucceess, isCreatePhysicalAddressSuccess, isCreateMailingAddressSuccess]);

  useEffect(() => {
    if (shouldRedirectToTrustedContact()) {
      navigate(`/accounts/${account?.id}/trusted-contact`);
    }
  }, [
    isCreatePersonalInformationSucceess,
    isPatchPersonalInformationSuccess,
    isCreatePhysicalAddressSuccess,
    isPatchPhysicalAddressSuccess,
    isCreateMailingAddressSuccess,
    isPatchMailingAddressSuccess,
  ]);

  useEffect(() => {
    return () => {
      dispatch(clearToastMessages());
    };
  }, []);

  const renderAccountNumberIfFound = () => {
    if (!account?.accountNumber) {
      return null;
    }

    return (
      <Row>
        <Col span={18} xs={24} sm={24} className={Styles.formCard}>
          <Row>
            <Col span={12} xs={12} sm={12} className={Styles.fieldLabel}>
              Account Number
            </Col>
            <Col span={12} xs={12} sm={12} className={Styles.accountNumberValue}>
              <Tooltip
                placement='topRight'
                title={
                  <>
                    <i className={`ri-checkbox-circle-fill`} />
                    <span>Account Number Copied to clipboard</span>
                  </>
                }
                open={!!showTooltip}
                overlayClassName={Styles.copiedTooltip}>
                <div className={Styles.displayValue} onClick={copyToClipboard}>
                  {account?.accountNumber}
                </div>
              </Tooltip>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  return (
    <MyAccountLayout
      sidebarMenuItemKey={MyAccountSidebarMainMenuItemKey.PersonalInformation}
      title={isMobile ? undefined : 'Personal'}>
      {renderAccountNumberIfFound()}
      <AccountHolderPersonalInformation />
      <AccountHolderPhysicalAddress />
      <AccountHolderMailingAddress />
    </MyAccountLayout>
  );
};
