import { TB_STATUSES } from '../../pages/Investigations/AccountStatus/constants';

export type HeaderComponentType = {
  authToken: string;
  firstName: string;
  lastName: string;
  accountValue: number;
  accountNumber: string;
  cashBalance: number;
  user: any;
  logout: Function;
  getAuthenticatedInUser: Function;
};

export type DesktopPropsType = {
  authToken: string;
  firstName: string;
  lastName: string;
  accountNumber: string;
  logout: Function;
  pathName: string;
  accountStatus: string;
  isUserActionNeeded: boolean;
};

export type MobilePropsType = {
  authToken: string;
  firstName: string;
  lastName: string;
  accountNumber: string;
  logout: Function;
  pathName: string;
  accountStatus: string;
  isUserActionNeeded: boolean;
};

export enum HeaderMenuItemKey {
  MyAccount = 'MyAccount',
  MoneyTransfers = 'MoneyTransfers',
  AccountStatus = 'AccountStatus',
  AccountStatements = 'AccountStatements',
  ChangeEmail = 'ChangeEmail',
  IndicationsOfInterest = 'IndicationsOfInterst',
}

export interface HeaderMenuItem {
  key: HeaderMenuItemKey;
  name: string;
  path: (id?: string) => string;
}

export const headerMenuItems: HeaderMenuItem[] = [
  {
    key: HeaderMenuItemKey.MyAccount,
    name: 'My Account',
    path: (id?: string) => `/accounts/${id}/personal-information`,
  },
  {
    key: HeaderMenuItemKey.MoneyTransfers,
    name: 'Money Transfers & Preferences',
    path: (id?: string) => `/accounts/${id}/money-transfers`,
  },
  //TODO Activate when the communication preferences will be implemented
  /*{ label: 'Security & Communication Preferences', url: 'securities' },*/

  //TODO Activate when statements and documents will be implemented
  /*{ label: 'Statements & Documents', url: 'docs' },*/
  {
    key: HeaderMenuItemKey.AccountStatus,
    name: 'Account Status',
    path: (id?: string) => `/accounts/${id}/account-status`,
  },
  {
    key: HeaderMenuItemKey.AccountStatements,
    name: 'Statements & Documents',
    path: (id?: string) => `/accounts/${id}/statements`,
  },
  { key: HeaderMenuItemKey.ChangeEmail, name: 'Change Email Address & Phone Number', path: () => '/user/settings' },
  {
    key: HeaderMenuItemKey.IndicationsOfInterest,
    name: 'Indications of Interest',
    path: () => `/user/indications-of-interest`,
  },
];

export const completedAccountMenuItemKeyList = [
  HeaderMenuItemKey.MoneyTransfers,
  HeaderMenuItemKey.AccountStatus,
  HeaderMenuItemKey.AccountStatements,
  HeaderMenuItemKey.ChangeEmail,
];

export const getAccountStatusClassName = (status: string): string => {
  switch (status) {
    case TB_STATUSES.Rejected:
      return 'userStatusRejected';
    case TB_STATUSES.New:
    case TB_STATUSES.Pending:
    case TB_STATUSES.InvestigationSubmitted:
    case TB_STATUSES.ReadyForBackOffice:
    case TB_STATUSES.BackOffice:
    case TB_STATUSES.AccountSetup:
    case TB_STATUSES.ActionRequired:
    case TB_STATUSES.Suspended:
    case TB_STATUSES.Cancelled:
    case TB_STATUSES.PendingSubmit:
      return 'userStatusPending';
    default:
      return 'userStatusNeutral';
  }
};

export const getBannerText = (isUserActionNeeded: boolean, navigate: any, accoundUuId?: string) => {
  if (isUserActionNeeded)
    return (
      <span>
        Before we can approve your account, we will need additional information. Please visit{' '}
        <a onClick={() => (accoundUuId ? navigate(`/accounts/${accoundUuId}/account-status`) : null)}>Account Status</a>{' '}
        to submit the required information.
      </span>
    );

  return (
    <span>
      Your account application has been submitted and is under review. You can find more information at{' '}
      <a onClick={() => (accoundUuId ? navigate(`/accounts/${accoundUuId}/account-status`) : null)}>Account Status</a>
    </span>
  );
};
