import { type ChangeEvent, type ReactNode } from 'react';

import { Col, Input, Row } from 'antd';

import { MFormItemError } from '../MFormItemError/MFormItemError';

import * as Styles from './MInput.styles';

export interface MInputProps {
  placeholder?: string;
  value?: string | number;
  defaultValue?: string;
  error?: string;
  onChange: (value: string) => void;
  addOnBefore?: ReactNode;
  addOnAfter?: ReactNode;
  disabled?: boolean;
  className?: string;
  size?: 'small' | 'middle' | 'large';
  weight?: 'light' | 'medium';
  testId?: string;
}

export const MInput = ({
  placeholder,
  value,
  defaultValue,
  error,
  onChange,
  disabled,
  addOnAfter,
  addOnBefore,
  size = 'middle',
  weight = 'medium',
  className = ' ',
  testId,
}: MInputProps) => {
  const _onChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <Row className={Styles.inputContainer}>
      <Col span={24}>
        <Input
          data-testid={testId}
          size={size}
          placeholder={placeholder}
          value={value}
          defaultValue={defaultValue}
          onChange={_onChange}
          addonBefore={addOnBefore}
          addonAfter={addOnAfter}
          className={Styles.input(weight).concat(' ').concat(className).trimEnd()}
          disabled={disabled}
        />
      </Col>
      <Col span={24} data-testid={testId + '-' + 'error'}>
        <MFormItemError value={error} />
      </Col>
    </Row>
  );
};
