import { useEffect, useState } from 'react';

import { Row, Space } from 'antd';
import { Formik } from 'formik';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { addOfferOrderIntent, removeOfferOrderIntent } from 'src/actions';
import { useAccountSelector } from 'src/hooks';
import { WhatsRestrictedPersonModal } from 'src/lib';
import { OfferOrderIntent, OfferDetails } from 'src/models';
import { isRegAPlusOffer, isRegDTypeCOffer, assertNonNullable, assertPropertyIsFound } from 'src/utils';

import { DefinitionConstant } from '../../../../../constants/common.constants';
import { useAccountBalance } from '../../../../../hooks/useAccountBalance';
import { CurrencyRangeField } from '../../../../../lib/CurrencyRangeField/CurrencyRangeField';
import Checkbox from '../../../../../lib/FormComponents/CheckBox';
import { InputType, TextAlign } from '../../../../../lib/FormComponents/Input/constants';
import Input from '../../../../../lib/FormComponents/Input/Input';
import { MButton } from '../../../../../lib/FormComponents/MButton/MButton';
import NumberInput from '../../../../../lib/FormComponents/NumberInput/NumberInput';
import { NewOfferOrderLayout } from '../../../../../lib/Layout/NewOfferOrderLayout/NewOfferOrderLayout';
import CurrencyField from '../../../../../lib/Miscellaneous/FormattedFields/CurrencyField/CurrencyField';
import ListRow from '../../../../../lib/Miscellaneous/ListRow/ListRow';
import { MTooltip } from '../../../../../lib/MTooltip/MTooltip';
import { ThisOfferOrderWillDisclaimer } from '../../../../../lib/ThisOfferOrderWillDisclaimer/ThisOfferOrderWillDisclaimer';
import { Images } from '../../../../../styles';
import { DOCUMENTS_PATH } from '../NewOfferOrderDocumentsStep/NewOfferOrderDocumentsStep';

import { Accreditation } from './components/Accreditation';
import { escrowOfferFields, InvestorType, normalOfferFields } from './constants';
import * as Styles from './NewOfferOrder.styles';
import { conditionalOfferOrderValidation, standardOfferValidation } from './NewOfferOrder.validations';

export const ORDER_PATH = 'new';

export interface NewOfferOrderForm {
  quantity?: string;
  isNotRestrictedPerson?: boolean;
  investorType?: InvestorType;
  totalInvestment?: string;
}

export const NewOfferOrder = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [offerOrderData, setOfferOrderData] = useState<any[]>([]);
  const offerDetails: OfferDetails = useSelector((state: any) => state.offers.offerDetails?.data);
  const { account } = useAccountSelector();

  const offerOrderIntent: OfferOrderIntent | null = useSelector((state: any) => state.offerOrders.intent);

  const { cashAvailable } = useAccountBalance();

  const [isAccreditationValid, setIsAccreditationValid] = useState<boolean>(true); // NOTE-LA: Unable to synchronize accreditation form validation with offer order. Quantity field overwrites form errors.

  const [isWhatsRestrictedPersonModalOpen, setIsWhatsRestrictedPersonModalOpen] = useState(false);

  const findTotalCost = (quantity?: string): number =>
    Number((Number(quantity) * offerDetails.pricePerShare).toFixed(2));

  const allowedFields = offerDetails?.isExternalOffer === true ? escrowOfferFields : normalOfferFields;

  const shouldReuseOfferOrderIntent = () =>
    params.offerId && offerOrderIntent && params.offerId === offerOrderIntent.offerId;

  const shouldDisplayAccreditation = () => isRegAPlusOffer(offerDetails) || isRegDTypeCOffer(offerDetails);

  const hasManySecurityDeliveryOptions = () =>
    Boolean(
      offerDetails.isExternalOffer &&
        offerDetails.externalDetails &&
        offerDetails.externalDetails.securityDeliveryOptions.length > 1,
    );

  const getConditionalOfferInitialFormValues = () => {
    let result: NewOfferOrderForm = {
      totalInvestment: shouldReuseOfferOrderIntent() ? offerOrderIntent?.totalInvestment?.toString() ?? '' : '',
    };

    if (!offerDetails.allowRestrictedPersons) {
      result.isNotRestrictedPerson = shouldReuseOfferOrderIntent()
        ? offerOrderIntent?.isNotRestrictedPerson ?? false
        : false;
    }

    return result;
  };

  const getStandardOfferInitialFormValues = () => {
    let result: NewOfferOrderForm = {
      quantity: shouldReuseOfferOrderIntent() ? offerOrderIntent?.quantity?.toString() ?? '' : '',
    };

    if (!offerDetails.allowRestrictedPersons) {
      result.isNotRestrictedPerson = shouldReuseOfferOrderIntent()
        ? offerOrderIntent?.isNotRestrictedPerson ?? false
        : false;
    }

    return result;
  };

  const getInitialFormValues = () => {
    if (offerDetails.isConditional) {
      return getConditionalOfferInitialFormValues();
    }

    return getStandardOfferInitialFormValues();
  };

  const onSubmit = (values: NewOfferOrderForm) => {
    assertNonNullable(account, 'account');
    assertPropertyIsFound(account, 'accountId');
    const totalInvestment = offerDetails.isConditional
      ? Number(values.totalInvestment)
      : findTotalCost(values.quantity);
    dispatch(
      addOfferOrderIntent({
        ...offerOrderIntent,
        isConditional: offerDetails.isConditional,
        isExternal: offerDetails.isExternalOffer,
        hasManySecurityDeliveryOptions: hasManySecurityDeliveryOptions(),
        minSharePrice: offerDetails.minSharePrice,
        maxSharePrice: offerDetails.maxSharePrice,
        accountId: account.accountId,
        offerId: offerDetails.id,
        quantity: Number(values.quantity),
        price: offerDetails.pricePerShare,
        totalInvestment,
        cashAvailable,
        isNotRestrictedPerson: Boolean(values.isNotRestrictedPerson),
        isAccreditedInvestor: values.investorType ? values.investorType === InvestorType.Accredited : undefined,
        areDocumentsReviewed: offerOrderIntent?.areDocumentsReviewed ?? false,
        allowAchTransfers: offerDetails.allowAchTransfers,
        allowWireTransfers: offerDetails.allowWireTransfers,
        allowCheckTransfers: offerDetails.allowCheckTransfers,
        allowCardPayments: offerDetails.allowCardPayments,
        cardPaymentAccountExternalId: offerDetails.cardPaymentAccount?.externalId,
        cardPaymentAccountStatus: offerDetails.cardPaymentAccount?.status,
        wireInstructions: offerDetails.externalDetails?.wireInstructions,
        checkInstructions: offerDetails.externalDetails?.checkInstructions,
      }),
    );

    window.gtag('event', 'offer_order_invest_amount_complete', {
      offer_id: offerDetails.id,
      offer_name: offerDetails.name,
      account_id: account.accountId,
      offer_order_quantity: Number(values.quantity),
      offer_order_value: totalInvestment,
    });

    navigate(`../${DOCUMENTS_PATH}`);
  };

  useEffect(() => {
    const data = [
      {
        label: 'Cash Available',
        componentLabel: () => (
          <span className={Styles.cashAvailableLabelContainer}>
            <span>Cash Available</span>
            <MTooltip placement='top'>{DefinitionConstant.CASH_AVAILABLE} </MTooltip>
          </span>
        ),
        value: <CurrencyField value={Number(cashAvailable)} />,
      },
      {
        label: 'Minimum Investment Amount',
        value: <CurrencyField value={offerDetails?.minimumInvestCurrencyAmt} />,
      },
    ];

    if (offerDetails.isConditional) {
      data.push({
        label: 'Share Range',
        value: <CurrencyRangeField min={offerDetails.minSharePrice} max={offerDetails.maxSharePrice} />,
      });
    } else {
      data.push({
        label: 'Share Price',
        value: <CurrencyField value={offerDetails?.pricePerShare} />,
      });
    }
    setOfferOrderData(data);
  }, [cashAvailable, offerDetails]);

  useEffect(() => {
    if (!shouldReuseOfferOrderIntent()) {
      dispatch(removeOfferOrderIntent());
    }
  }, []);

  return (
    <NewOfferOrderLayout
      step='new'
      offerId={offerDetails.id}
      hasManySecurityDeliveryOptions={hasManySecurityDeliveryOptions()}>
      <Formik<NewOfferOrderForm>
        initialValues={getInitialFormValues()}
        onSubmit={values => {
          onSubmit(values);
        }}
        validationSchema={
          offerDetails.isConditional
            ? conditionalOfferOrderValidation(offerDetails)
            : standardOfferValidation(offerDetails)
        }>
        {form => {
          return (
            <div>
              <Row className={Styles.rowStyle}>
                <img
                  className={Styles.offerLogo}
                  src={offerDetails.logoUrl || Images.DefaultOffering}
                  alt={`${offerDetails.name}_logo`}
                />
                <span className={Styles.offerName}>{offerDetails.name}</span>
              </Row>

              <div className={Styles.fieldsContainer}>
                {offerOrderData.map(item => {
                  if (allowedFields.indexOf(item.label) > -1)
                    return (
                      <ListRow
                        testId={item.label?.toLowerCase().replace(/ /g, '-')}
                        key={`${item.label}_key`}
                        customRowStyle={{ borderBottom: 'none' }}
                        leftComponent={
                          <div className={Styles.fieldLabel}>
                            {item.componentLabel ? item.componentLabel() : item.label}
                          </div>
                        }
                        rightComponent={<div className={Styles.offerOrderValues}>{item.value}</div>}
                      />
                    );
                })}
                <ListRow
                  testId={'share-quantity'}
                  customRowStyle={{ borderBottom: 'none' }}
                  leftComponent={
                    <div className={Styles.fieldLabel}>
                      {offerDetails.isConditional ? 'Total Investment' : 'Share Quantity'}
                    </div>
                  }
                  rightComponent={
                    offerDetails?.isConditional ? (
                      <NumberInput
                        {...form}
                        placeholder='Enter Total Investment'
                        customStyles={TextAlign.Left}
                        name='totalInvestment'
                        value={form.values.totalInvestment}
                        step={'0.01'}
                        prefix={'$'}
                      />
                    ) : (
                      <Input
                        {...form}
                        name='quantity'
                        type={InputType.Number}
                        value={form.values.quantity}
                        placeholder='Enter Share Quantity'
                        textAlign={TextAlign.Left}
                      />
                    )
                  }
                />
                <div className={Styles.totalInvestment} data-testid={'total-investment'}>
                  <span>Total Investment</span>
                  {offerDetails.isConditional ? (
                    <CurrencyField
                      value={Number(form.values.totalInvestment)}
                      className={Styles.totalInvestmentValue}
                    />
                  ) : (
                    <CurrencyField
                      value={offerDetails.pricePerShare * Number(form.values['quantity'])}
                      className={Styles.totalInvestmentValue}
                    />
                  )}
                </div>

                {offerDetails.isConditional && (
                  <ThisOfferOrderWillDisclaimer min={offerDetails.minSharePrice} max={offerDetails.maxSharePrice} />
                )}
                {shouldDisplayAccreditation() && (
                  <Accreditation
                    form={form}
                    isAccreditationValid={(isValid: boolean) => {
                      setIsAccreditationValid(isValid);
                    }}
                  />
                )}
                {!offerDetails.allowRestrictedPersons && (
                  <>
                    <Checkbox
                      testId={'offer-order-check-restricted-person'}
                      customStyles={{ marginTop: 40 }}
                      name='isNotRestrictedPerson'
                      label='By checking I am not considered a “restricted person” and am eligible to participate in this offering'
                      {...form}
                      value={form.values.isNotRestrictedPerson}
                    />
                    <div
                      onClick={() => {
                        setIsWhatsRestrictedPersonModalOpen(true);
                      }}
                      className={Styles.restrictedPersonText}>
                      <i style={Styles.infoLogo} className={`ri-information-line`} />
                      What is a restricted person?
                    </div>
                  </>
                )}
                <Row justify='center' align='middle'>
                  <Space direction='horizontal'>
                    <MButton type='tertiary' onClick={() => navigate(-1)} testId={'offer-order-btn-back'}>
                      Back
                    </MButton>
                    <MButton
                      disabled={!_.isEmpty(form.errors) || !isAccreditationValid}
                      onClick={() => form.submitForm()}
                      testId={'offer-order-btn-review'}>
                      Review Disclosures
                    </MButton>
                  </Space>
                </Row>
              </div>
            </div>
          );
        }}
      </Formik>
      <WhatsRestrictedPersonModal
        isOpen={isWhatsRestrictedPersonModalOpen}
        onClose={() => setIsWhatsRestrictedPersonModalOpen(false)}
      />
    </NewOfferOrderLayout>
  );
};
