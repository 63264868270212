import { AnyAction } from 'redux';

import { createAction, State, Type } from './utils';

export const getInvestigations = (accountId?: number): AnyAction =>
  createAction(State.actionRequested(Type.GET_INVESTIGATIONS), { accountId });

export const uploadInvestigationDocument = (accountId: string, investigationId: number, body: any): AnyAction =>
  createAction(State.actionRequested(Type.UPLOAD_INVESTIGATION), { accountId, investigationId, body });

export const uploadApplicationInvestigationDocument = (
  accountId: string,
  accountHolderId: number,
  body: any,
): AnyAction =>
  createAction(State.actionRequested(Type.UPLOAD_APPLICATION_INVESTIGATION), { accountId, accountHolderId, body });
