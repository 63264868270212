import { css } from '@emotion/css';
import {
  AlignContent,
  BorderRadius,
  ColorConstant,
  Display,
  Font,
  FontWeight,
  JustifyContent,
  Spacing,
} from 'src/styles';

export const section = css`
  border: none;
  box-shadow: 0rem 0.25rem 1rem rgba(2, 85, 163, 0.15);
  ${Spacing.p24}
  ${Spacing.my24}
  ${BorderRadius.xs}
`;

export const header = css`
  ${Display.flex}
  ${AlignContent.center}
  ${JustifyContent.spaceBetween}
`;

export const title = css`
  ${Spacing.mb32}
  ${FontWeight.bold}
  ${Font.h2}
  color: ${ColorConstant.GRAY8};
`;
