import { useEffect, useState } from 'react';

import { Row, Space } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addOfferOrderIntent, offerOrdersCreateRequested } from 'src/actions';
import { useAccountSelector } from 'src/hooks';
import { SecurityDeliveryOption } from 'src/lib';
import { OfferDetails, OfferOrder, OfferOrderIntent, OfferOrderPaymentType } from 'src/models';

import { MAIN_PATH } from '../../../';
import { DefinitionConstant } from '../../../../../constants/common.constants';
import { OfferOrderPaymentTypeDto } from '../../../../../dtos/offerOrders.dtos';
import { useAccountBalance } from '../../../../../hooks/useAccountBalance';
import { CurrencyRangeField } from '../../../../../lib/CurrencyRangeField/CurrencyRangeField';
import { MButton } from '../../../../../lib/FormComponents/MButton/MButton';
import { NewOfferOrderLayout } from '../../../../../lib/Layout/NewOfferOrderLayout/NewOfferOrderLayout';
import CurrencyField from '../../../../../lib/Miscellaneous/FormattedFields/CurrencyField/CurrencyField';
import NumberField from '../../../../../lib/Miscellaneous/FormattedFields/NumberField/NumberField';
import ListRow from '../../../../../lib/Miscellaneous/ListRow/ListRow';
import Spinner from '../../../../../lib/Miscellaneous/Spinner';
import { MTooltip } from '../../../../../lib/MTooltip/MTooltip';
import { ThisOfferOrderWillDisclaimer } from '../../../../../lib/ThisOfferOrderWillDisclaimer/ThisOfferOrderWillDisclaimer';
import { Images } from '../../../../../styles';
import { SUMMARY_PATH } from '../../Summary/Summary';

import { PaymentTypeSelector } from './components/PaymentTypeSelector';
import { TermsAndConditionsModal } from './components/TermsAndConditionsModal';
import * as Styles from './NewOfferOrderConfirmStep.styles';

export const DETAILS_PATH = 'details';

export const NewOfferOrderConfirmStep = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const offerDetails: OfferDetails = useSelector((state: any) => state.offers.offerDetails?.data);

  const offerOrderIntent: OfferOrderIntent = useSelector((state: any) => state.offerOrders.intent);

  const offerOrder: OfferOrder | undefined = useSelector((state: any) => state.offerOrders.create.data);
  const isCreateOfferOrderLoading = useSelector((state: any) => Boolean(state.offerOrders.create.__requested));
  const createOfferOrderSucceeded = useSelector((state: any) => Boolean(state.offerOrders.create.__succeeded));
  const createOfferOrderFailed = useSelector((state: any) => Boolean(state.offerOrders.create.__failed));

  const isConfirmCardLoading = useSelector((state: any) => Boolean(state.offerOrders.confirmCardPayment.__requested));

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [confirmOrderFields, setConfirmOrderFields] = useState<
    { label: string; value: string | JSX.Element; componentLabel?: () => JSX.Element }[]
  >([]);
  const [selectedPaymentType, setSelectedPaymentType] = useState<OfferOrderPaymentTypeDto | undefined>(
    offerOrderIntent.paymentType?.value,
  );
  const [isSelectedPaymentTypeValid, setIsSelectedPaymentTypeValid] = useState<boolean>(true);

  const { account } = useAccountSelector();
  const { cashAvailable, isAccountBalanceLoading } = useAccountBalance();

  const hasEnoughMoney = () => offerOrderIntent.totalInvestment <= cashAvailable;

  const onIsSelectedPaymentTypeValid = (value: boolean) => {
    setIsSelectedPaymentTypeValid(value);
  };

  const onSelectedPaymentTypeChange = (value: OfferOrderPaymentTypeDto) => {
    setSelectedPaymentType(value);

    if (value === OfferOrderPaymentTypeDto.Ach) {
      dispatch(addOfferOrderIntent({ ...offerOrderIntent, paymentType: new OfferOrderPaymentType(value) }));
    } else {
      dispatch(
        addOfferOrderIntent({
          ...offerOrderIntent,
          paymentType: new OfferOrderPaymentType(value),
          achDeposit: undefined,
        }),
      );
    }
  };

  const submitOfferOrder = async () => {
    dispatch(offerOrdersCreateRequested(offerOrderIntent));
  };

  useEffect(() => {
    if (createOfferOrderSucceeded && offerOrder && !offerOrderIntent.paymentType?.isCard) {
      navigate(`../${offerOrder.id}`);
    }
  }, [createOfferOrderSucceeded]);

  useEffect(() => {
    if (createOfferOrderFailed) {
      navigate(`../failed`);
    }
  }, [createOfferOrderFailed]);

  useEffect(() => {
    const data: { label: string; value: string | JSX.Element; componentLabel?: () => JSX.Element }[] = [
      {
        label: 'Investor Name',
        value: `${account?.primaryAccountHolder?.firstName} ${account?.primaryAccountHolder?.lastName}`,
      },
      {
        label: 'Address',
        value: `${account?.primaryAccountHolder?.physicalAddress?.address1}, ${account?.primaryAccountHolder?.physicalAddress?.city}, ${account?.primaryAccountHolder?.physicalAddress?.postalCode}, ${account?.primaryAccountHolder?.physicalAddress?.country?.value}`,
      },
      { label: 'Date', value: moment(new Date().toISOString()).format('MM/DD/YYYY') },
    ];

    if (offerOrderIntent.isConditional) {
      data.push({
        label: 'Share Range',
        value: <CurrencyRangeField min={offerOrderIntent.minSharePrice} max={offerOrderIntent.maxSharePrice} />,
      });
    } else {
      data.push(
        {
          label: 'Share Price',
          value: <CurrencyField value={offerOrderIntent.price} />,
        },
        {
          label: 'Share Quantity',
          value: <NumberField value={Number(offerOrderIntent.quantity)} />,
        },
      );
    }

    if (hasEnoughMoney() && !offerOrderIntent.isExternal) {
      data.push({
        label: 'Cash Available',
        componentLabel: () => (
          <span className={Styles.cashAvailableLabelContainer}>
            <span>Cash Available</span>
            <MTooltip placement='top'>{DefinitionConstant.CASH_AVAILABLE}</MTooltip>
          </span>
        ),
        value: <CurrencyField value={Number(cashAvailable)} />,
      });
    }

    if (offerOrderIntent.isExternal && offerOrderIntent.securityDeliveryOption) {
      data.push({
        label: 'Security Delivery Option',
        value: <SecurityDeliveryOption {...offerOrderIntent.securityDeliveryOption} />,
      });
    }

    setConfirmOrderFields(data);
  }, [offerOrderIntent, cashAvailable, account]);

  useEffect(() => {
    if (hasEnoughMoney() && !offerOrderIntent.isExternal) {
      dispatch(addOfferOrderIntent({ ...offerOrderIntent, paymentType: undefined, achDeposit: undefined }));
    }
  }, [offerOrderIntent.isExternal, cashAvailable]);

  if (isAccountBalanceLoading) return <Spinner />;

  return (
    <NewOfferOrderLayout
      step='confirm'
      hasManySecurityDeliveryOptions={offerOrderIntent.hasManySecurityDeliveryOptions}
      offerId={offerDetails.id}>
      <div>
        <Row className={Styles.rowStyle}>
          <img
            className={Styles.offerLogo}
            src={offerDetails.logoUrl || Images.DefaultOffering}
            alt={`${offerDetails.name}_logo`}
          />
          <span className={Styles.offerName}>{offerDetails.name}</span>
        </Row>

        <div className={Styles.fieldsContainer}>
          {confirmOrderFields.map(item => (
            <ListRow
              testId={`confirm-order-${item.label?.toLowerCase().replace(/ /g, '-')}`}
              key={`${item.label}_key`}
              customRowStyle={{ borderBottom: 'none' }}
              leftComponent={
                <div className={Styles.fieldLabel}>{item.componentLabel ? item.componentLabel() : item.label}</div>
              }
              rightComponent={<div className={Styles.fieldValue}>{item.value}</div>}
            />
          ))}
        </div>

        <div className={Styles.totalInvestment} data-testid={'confirm-order-total-invest'}>
          Total Investment
          <CurrencyField value={offerOrderIntent.totalInvestment} className={Styles.totalInvestmentValue} />
        </div>

        {offerOrderIntent.isConditional && (
          <ThisOfferOrderWillDisclaimer min={offerOrderIntent.minSharePrice} max={offerOrderIntent.maxSharePrice} />
        )}

        {(!hasEnoughMoney() || offerOrderIntent.isExternal) && (
          <PaymentTypeSelector
            value={selectedPaymentType}
            isValid={onIsSelectedPaymentTypeValid}
            onChange={onSelectedPaymentTypeChange}
          />
        )}

        <div className={Styles.policy} data-testid={'confirm-order-policy'}>
          POLICY
          <br />
          Once you place your offer order, you will be able to cancel it in the time span that the order is in a pending
          state. Once the order goes through, you can track it through the{' '}
          <span onClick={() => navigate(`${MAIN_PATH}/${SUMMARY_PATH}`)} className={Styles.linkStyle}>
            Summary Page
          </span>
          .
        </div>

        <Row justify='center' align='middle' className={Styles.buttonsContainer}>
          <Space direction='horizontal'>
            <MButton type='tertiary' onClick={() => navigate(-1)} testId={'offer-order-btn-back'}>
              Back
            </MButton>
            <MButton
              onClick={() => submitOfferOrder()}
              disabled={!isSelectedPaymentTypeValid || isCreateOfferOrderLoading || isConfirmCardLoading}
              loading={isCreateOfferOrderLoading || isConfirmCardLoading}
              testId={'confirm-order-btn-placeorder'}>
              Place Order
            </MButton>
          </Space>
        </Row>
        <div className={Styles.termsAndConditions} data-testid={'confirm-order-terms-conditions'}>
          By clicking “Place Order”, I acknowledge the&nbsp;
          <span onClick={() => setIsModalVisible(true)} className={Styles.linkStyle}>
            terms and conditions
          </span>
        </div>
      </div>
      <TermsAndConditionsModal
        isOpen={isModalVisible}
        onClose={() => {
          setIsModalVisible(false);
        }}
      />
    </NewOfferOrderLayout>
  );
};
