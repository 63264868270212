import * as CashieringActions from './cashiering';
import * as ConfigsActions from './configs';
import * as InvestigationsActions from './investigations';
import * as QuoteMediaActions from './quoteMedia';

/**
 * Login Forms
 */
export * from './auth';

export * from './accounts.actions';
export * from './accountHolders.actions';
/**
 * User Forms
 */
export * from './users.actions';

/**
 * Account Details Actions
 */
export * from './accountDetails';

/**
 * Acconut Statement Actions;
 */
export * from './statements.actions';

/**
 * Cashiering actions
 */
export const getPlaidLinkToken = CashieringActions.getPlaidLinkToken;
export const getACHRelationships = CashieringActions.getACHRelationships;
export const getACHRelationshipsSucceeded = CashieringActions.getACHRelationshipsSucceeded;
export const createACHRelationship = CashieringActions.createACHRelationship;
export const createACHRelationshipSucceeded = CashieringActions.createACHRelationshipSucceeded;
export const unlinkBankAccount = CashieringActions.unlinkBankAccount;
export const editNickname = CashieringActions.editNickname;
export const depositFunds = CashieringActions.depositFunds;
export const withdrawFunds = CashieringActions.withdrawFunds;
export const getTransfers = CashieringActions.getTransfers;
export const getTransactionDetails = CashieringActions.getTransactionDetails;
export const cancelDeposit = CashieringActions.cancelDeposit;
export const cancelWithdraw = CashieringActions.cancelWithdraw;
export const clearACHRelationships = CashieringActions.clearACHRelationships;

/**
 * Offers actions
 */
export * from './offers.actions';

/**
 * Offer Watches
 */
export * from './offerWatches.actions';

/**
 * Investigations actions
 */
export const getInvestigations = InvestigationsActions.getInvestigations;
export const uploadInvestigationDocument = InvestigationsActions.uploadInvestigationDocument;
export const uploadApplicationInvestigationDocument = InvestigationsActions.uploadApplicationInvestigationDocument;

/**
 * Configs actions
 */
export const getConfigs = ConfigsActions.configsRequested;

/**
 * Quote Media
 */
export const getSnapQuotesRequested = QuoteMediaActions.getSnapQuotesRequested;
export const getEnhancedSnapQuotesRequested = QuoteMediaActions.getEnhancedSnapQuotesRequested;
export const getChartSnapQuotesClear = QuoteMediaActions.getChartSnapQuotesClear;
export const getChartSnapQuotesRequested = QuoteMediaActions.getChartSnapQuotesRequested;
export const getSnapQuotesSucceeded = QuoteMediaActions.getSnapQuotesSucceeded;
export const getEnhancedSnapQuotesSucceeded = QuoteMediaActions.getEnhancedSnapQuotesSucceeded;
export const getChartSnapQuotesSucceeded = QuoteMediaActions.getChartSnapQuotesSucceeded;

/**
 * Orders
 */
export * from './orders.actions';

/**
 * Offer Orders
 */
export * from './offerOrders.actions';

/**
 * ToastMessages actions
 */
export * from './toastMessages';

/**
 * App actions
 */
export * from './app';
