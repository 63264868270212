import moment from 'moment';
import { DateConstant } from 'src/constants';

import { MFormTextField } from '../MFormTextField/MFormTextField';

interface MFormDateFieldProps {
  label: string;
  value?: string | number;
}

export const MFormDateField = ({ label, value }: MFormDateFieldProps) => (
  <MFormTextField label={label} value={moment(value).format(DateConstant.US_FORMAT)} />
);
