import { css } from '@emotion/css';
import { Font } from 'src/styles';

export const label = css`
  ${Font.lg}
`;

export const description = css`
  ${Font.sm}
`;
