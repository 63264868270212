import { Col, Form, Row } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { ScreenBreakpoint } from 'src/styles';

import { MFormItemLabel } from '../MFormItemLabel/MFormItemLabel';
import { PhoneNumberInput, PhoneNumberInputProps } from '../PhoneNumberInput/PhoneNumberInput';

import * as Styles from './MFormPhoneNumberInput.styles';

interface MFormPhoneNumberInputProps extends PhoneNumberInputProps {}

export const MFormPhoneNumberInput = ({
  onChange,
  options,
  defaultValue,
  disabled,
  align = 'horizontal',
  error,
  testId,
}: MFormPhoneNumberInputProps) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${ScreenBreakpoint.mobile.max})` });

  return (
    <Form.Item colon={false}>
      <Row className={Styles.contentContainer(align)}>
        <Col span={isMobile ? 24 : align === 'horizontal' ? 7 : 24} className={Styles.col}>
          <MFormItemLabel value='Phone Number(s)' testId={testId + '-' + 'label'} />
        </Col>
        <PhoneNumberInput
          testId={testId}
          defaultValue={defaultValue}
          options={options}
          onChange={onChange}
          disabled={disabled}
          error={error}
          align={align}
        />
      </Row>
    </Form.Item>
  );
};
