import { css, cx } from '@emotion/css';

import { ColorConstant, Font, FontWeight } from '../../styles';

export const tooltip = (text: 'underline' | 'none') => css`
  text-decoration: ${text};

  color: ${text === 'underline' ? ColorConstant.BRAND6 : 'inherited'};

  &:hover {
    color: ${text === 'underline' ? ColorConstant.BRAND6 : 'inherited'};
  }

  &:focus {
    color: ${text === 'underline' ? ColorConstant.BRAND6 : 'inherited'};
  }
`;

export const icon = (icon: 'question' | 'info') =>
  cx(
    icon === 'question' ? 'ri-question-line' : 'ri-information-line',
    css`
      color: ${ColorConstant.BRAND5};
      vertical-align: middle;
      cursor: pointer;
      ${Font.lg}
      ${FontWeight.normal}
    `,
  );
