import { call, put, select, takeEvery } from 'redux-saga/effects';
import { doSucceededGetAccount, toastMessagesAdd } from 'src/actions';
import { mapAccountHolderBirthCountryDtoToModel, mapAccountHolderCitizenshipCountryDtoToModel } from 'src/mappers';
import {
  Account,
  AccountHolderMaritalStatus,
  AccountHolderAddress,
  AccountHolderTrustedContact,
  AccountHolderSuitabilityInformation,
  AccountHolderFinancialInformation,
  AccountHolderDisclosures,
} from 'src/models';
import { isAddressMismatchError } from 'src/utils';
import { v4 as uuidv4 } from 'uuid';

import { State, Type } from '../actions/utils';
import * as Url from '../constants/url';
import { AuthReducedState } from '../typings/auth.types';
import { ResponseGenerator, SeverityEnum, TReduxAction } from '../typings/commonTypes';

import { getErrorMessage, HttpClient, replacePlaceholders, safeSaga } from './utils';

export function* handleAddressMismatchResponseError({ error, actionType }: { error: any; actionType: string }) {
  yield put({
    type: State.actionFailed(actionType),
    message: { ...JSON.parse(getErrorMessage(error)).address, error: error?.response?.data?.error },
  });

  return;
}

function* createPersonalInformation(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);
  let bodyToSend = action.payload.data;
  const url = replacePlaceholders(Url.PERSONAL_INFORMATION, {
    accountIdentifier: 'individual',
  });

  const { data }: ResponseGenerator<any> = yield call(HttpClient, 'POST', url, bodyToSend, authToken);

  yield put(
    toastMessagesAdd({
      key: uuidv4(),
      severity: SeverityEnum.Success,
      message: 'Personal Information was successfully saved',
    }),
  );

  yield put({
    type: State.actionSucceeded(Type.CREATE_ACCOUNT_HOLDER_PERSONAL_INFORMATION),
  });

  const account: Account = yield select(state => state.accounts.item.data);
  yield put(
    doSucceededGetAccount({
      ...account,
      primaryAccountHolder: {
        ...account.primaryAccountHolder,
        numberOfDependents: data.numberOfDependents,
        maritalStatus: new AccountHolderMaritalStatus(data.maritalStatus),
        dateOfBirth: data.dateOfBirth,
        taxId: data.taxId,
        middleName: data.middleName,
        suffix: data.suffix,
      },
    }),
  );
  yield put({
    type: State.actionSucceeded(Type.CREATE_ACCOUNT_HOLDER_PERSONAL_INFORMATION),
  });
}

function* patchPersonalInformation(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);

  const url = replacePlaceholders(Url.PERSONAL_INFORMATION, {
    accountIdentifier: 'individual',
  });

  const { data }: ResponseGenerator<any> = yield call(HttpClient, 'PATCH', url, action.payload.data, authToken);

  yield put(
    toastMessagesAdd({
      key: uuidv4(),
      severity: SeverityEnum.Success,
      message: `Personal Information was successfully saved`,
    }),
  );
  const account: Account = yield select(state => state.accounts.item.data);
  yield put(
    doSucceededGetAccount({
      ...account,
      primaryAccountHolder: {
        ...account.primaryAccountHolder,
        numberOfDependents: data.numberOfDependents,
        maritalStatus: new AccountHolderMaritalStatus(data.maritalStatus),
      },
    }),
  );
  yield put({
    type: State.actionSucceeded(Type.PATCH_ACCOUNT_HOLDER_PERSONAL_INFORMATION),
  });
}

function* createPhysicalAddress(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);

  try {
    const url = replacePlaceholders(Url.PHYSICAL_ADDRESS_INFORMATION, {
      accountIdentifier: 'individual',
    });
    const { data }: ResponseGenerator<any> = yield call(HttpClient, 'POST', url, action.payload.data, authToken);

    yield put(
      toastMessagesAdd({
        key: uuidv4(),
        severity: SeverityEnum.Success,
        message: 'Physical Address Information was successfully saved',
      }),
    );
    yield put({
      type: State.actionSucceeded(Type.CREATE_ACCOUNT_HOLDER_PHYSICAL_ADDRESS),
    });
    const account: Account = yield select(state => state.accounts.item.data);
    yield put(
      doSucceededGetAccount({
        ...account,
        primaryAccountHolder: {
          ...account.primaryAccountHolder,
          physicalAddress: new AccountHolderAddress(data.physicalAddress),
          citizenshipCountry: mapAccountHolderCitizenshipCountryDtoToModel(data.citizenshipCountry),
          birthCountry: mapAccountHolderBirthCountryDtoToModel(data.birthCountry),
        },
      }),
    );
  } catch (error) {
    if (isAddressMismatchError(error)) {
      yield handleAddressMismatchResponseError({ error, actionType: Type.CREATE_ACCOUNT_HOLDER_PHYSICAL_ADDRESS });

      return;
    }
    throw error;
  }
}

function* patchPhysicalAddress(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);

  const url = replacePlaceholders(Url.PHYSICAL_ADDRESS_INFORMATION, {
    accountIdentifier: 'individual',
  });

  try {
    const { data }: ResponseGenerator<any> = yield call(HttpClient, 'PATCH', url, action.payload.data, authToken);
    yield put(
      toastMessagesAdd({
        key: uuidv4(),
        severity: SeverityEnum.Success,
        message: 'Physical Address Information was successfully saved',
      }),
    );
    yield put({
      type: State.actionSucceeded(Type.PATCH_ACCOUNT_HOLDER_PHYSICAL_ADDRESS),
    });
    const account: Account = yield select(state => state.accounts.item.data);
    yield put(
      doSucceededGetAccount({
        ...account,
        primaryAccountHolder: {
          ...account.primaryAccountHolder,
          physicalAddress: new AccountHolderAddress(data.physicalAddress),
          birthCountry: mapAccountHolderBirthCountryDtoToModel(data.birthCountry),
        },
      }),
    );
  } catch (error) {
    if (isAddressMismatchError(error)) {
      yield handleAddressMismatchResponseError({ error, actionType: Type.PATCH_ACCOUNT_HOLDER_PHYSICAL_ADDRESS });

      return;
    }
    throw error;
  }
}

function* createMailingAddress(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);

  const url = replacePlaceholders(Url.MAILING_ADDRESS_INFORMATION, {
    accountIdentifier: 'individual',
  });

  try {
    const { data }: ResponseGenerator<any> = yield call(HttpClient, 'POST', url, action.payload.data, authToken);
    yield put(
      toastMessagesAdd({
        key: uuidv4(),
        severity: SeverityEnum.Success,
        message: 'Mailing Address Information was successfully saved',
      }),
    );
    yield put({
      type: State.actionSucceeded(Type.CREATE_ACCOUNT_HOLDER_MAILING_ADDRESS),
    });
    const account: Account = yield select(state => state.accounts.item.data);
    yield put(
      doSucceededGetAccount({
        ...account,
        primaryAccountHolder: {
          ...account.primaryAccountHolder,
          mailingAddress: new AccountHolderAddress(data.mailingAddress),
        },
      }),
    );
  } catch (error) {
    if (isAddressMismatchError(error)) {
      yield handleAddressMismatchResponseError({ error, actionType: Type.CREATE_ACCOUNT_HOLDER_MAILING_ADDRESS });

      return;
    }
    throw error;
  }
}

function* patchMailingAddress(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);

  const url = replacePlaceholders(Url.MAILING_ADDRESS_INFORMATION, {
    accountIdentifier: 'individual',
  });

  try {
    const { data }: ResponseGenerator<any> = yield call(HttpClient, 'PATCH', url, action.payload.data, authToken);
    yield put(
      toastMessagesAdd({
        key: uuidv4(),
        severity: SeverityEnum.Success,
        message: `Mailing Address Information was successfully saved`,
      }),
    );
    yield put({
      type: State.actionSucceeded(Type.PATCH_ACCOUNT_HOLDER_MAILING_ADDRESS),
    });
    const account: Account = yield select(state => state.accounts.item.data);
    yield put(
      doSucceededGetAccount({
        ...account,
        primaryAccountHolder: {
          ...account.primaryAccountHolder,
          mailingAddress: new AccountHolderAddress(data.mailingAddress),
        },
      }),
    );
  } catch (error) {
    if (isAddressMismatchError(error)) {
      yield handleAddressMismatchResponseError({ error, actionType: Type.PATCH_ACCOUNT_HOLDER_MAILING_ADDRESS });

      return;
    }
    throw error;
  }
}

function* createTrustedContact(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);
  let url = replacePlaceholders(Url.TRUSTED_CONTACT_INFORMATION, {
    accountIdentifier: 'individual',
  });

  try {
    const { data }: ResponseGenerator<any> = yield call(HttpClient, 'POST', url, action.payload.data, authToken);
    yield put(
      toastMessagesAdd({
        key: uuidv4(),
        severity: SeverityEnum.Success,
        message: 'Trusted Contact Information was successfully saved',
      }),
    );
    yield put({
      type: State.actionSucceeded(Type.CREATE_ACCOUNT_HOLDER_TRUSTED_CONTACT),
    });
    const account: Account = yield select(state => state.accounts.item.data);
    yield put(
      doSucceededGetAccount({
        ...account,
        primaryAccountHolder: {
          ...account.primaryAccountHolder,
          trustedContact: new AccountHolderTrustedContact(data),
        },
      }),
    );
  } catch (error) {
    if (isAddressMismatchError(error)) {
      yield handleAddressMismatchResponseError({ error, actionType: Type.CREATE_ACCOUNT_HOLDER_TRUSTED_CONTACT });

      return;
    }
    throw error;
  }
}

function* patchTrustedContact(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);
  let url = replacePlaceholders(Url.TRUSTED_CONTACT_INFORMATION, {
    accountIdentifier: 'individual',
  });

  try {
    const { data }: ResponseGenerator<any> = yield call(HttpClient, 'PATCH', url, action.payload.data, authToken);
    yield put(
      toastMessagesAdd({
        key: uuidv4(),
        severity: SeverityEnum.Success,
        message: 'Trusted Contact Information was successfully saved',
      }),
    );
    yield put({
      type: State.actionSucceeded(Type.PATCH_ACCOUNT_HOLDER_TRUSTED_CONTACT),
    });
    const account: Account = yield select(state => state.accounts.item.data);
    yield put(
      doSucceededGetAccount({
        ...account,
        primaryAccountHolder: {
          ...account.primaryAccountHolder,
          trustedContact: new AccountHolderTrustedContact(data),
        },
      }),
    );
  } catch (error) {
    if (isAddressMismatchError(error)) {
      yield handleAddressMismatchResponseError({ error, actionType: Type.PATCH_ACCOUNT_HOLDER_TRUSTED_CONTACT });

      return;
    }
    throw error;
  }
}

function* deleteTrustedContact() {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);
  let url = replacePlaceholders(Url.TRUSTED_CONTACT_INFORMATION, {
    accountIdentifier: 'individual',
  });
  yield call(HttpClient, 'DELETE', url, undefined, authToken);
  yield put(
    toastMessagesAdd({
      key: uuidv4(),
      severity: SeverityEnum.Success,
      message: 'Trusted Contact Person was successfully removed',
    }),
  );
  yield put({
    type: State.actionSucceeded(Type.DELETE_ACCOUNT_HOLDER_TRUSTED_CONTACT),
  });
  const account: Account = yield select(state => state.accounts.item.data);
  yield put(
    doSucceededGetAccount({
      ...account,
      primaryAccountHolder: { ...account.primaryAccountHolder, trustedContact: undefined },
    }),
  );
}

function* createSuitabilityInformation(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);
  let url = replacePlaceholders(Url.SUITABILITY_INFORMATION, {
    accountIdentifier: 'individual',
  });

  const { data }: ResponseGenerator<any> = yield call(HttpClient, 'POST', url, action.payload?.data, authToken);
  yield put(
    toastMessagesAdd({
      key: uuidv4(),
      severity: SeverityEnum.Success,
      message: 'Suitability Information was successfully saved',
    }),
  );
  yield put({
    type: State.actionSucceeded(Type.CREATE_ACCOUNT_HOLDER_SUITABILITY_INFORMATION),
  });
  const account: Account = yield select(state => state.accounts.item.data);
  yield put(
    doSucceededGetAccount({
      ...account,
      primaryAccountHolder: {
        ...account.primaryAccountHolder,
        suitabilityInformation: new AccountHolderSuitabilityInformation(data),
      },
    }),
  );
}

function* patchSuitabilityInformation(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);
  let url = replacePlaceholders(Url.SUITABILITY_INFORMATION, {
    accountIdentifier: 'individual',
  });
  const { data }: ResponseGenerator<any> = yield call(HttpClient, 'PATCH', url, action.payload.data, authToken);
  yield put(
    toastMessagesAdd({
      key: uuidv4(),
      severity: SeverityEnum.Success,
      message: 'Suitability Information was successfully saved',
    }),
  );
  yield put({
    type: State.actionSucceeded(Type.PATCH_ACCOUNT_HOLDER_SUITABILITY_INFORMATION),
  });
  const account: Account = yield select(state => state.accounts.item.data);
  yield put(
    doSucceededGetAccount({
      ...account,
      primaryAccountHolder: {
        ...account.primaryAccountHolder,
        suitabilityInformation: new AccountHolderSuitabilityInformation(data),
      },
    }),
  );
}

function* createFinancialInformationAssets(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);

  const url = replacePlaceholders(Url.ASSETS_FINANCIAL_INFORMATION, {
    accountIdentifier: 'individual',
  });
  const { data }: ResponseGenerator<any> = yield call(HttpClient, 'POST', url, action.payload.data, authToken);

  yield put(
    toastMessagesAdd({
      key: uuidv4(),
      severity: SeverityEnum.Success,
      message: 'Assets Information was successfully saved',
    }),
  );
  yield put({
    type: State.actionSucceeded(Type.CREATE_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_ASSETS),
  });
  const account: Account = yield select(state => state.accounts.item.data);
  yield put(
    doSucceededGetAccount({
      ...account,
      primaryAccountHolder: {
        ...account.primaryAccountHolder,
        financialInformation: new AccountHolderFinancialInformation(data),
      },
    }),
  );
}

function* patchFinancialInformationAssets(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);

  const url = replacePlaceholders(Url.ASSETS_FINANCIAL_INFORMATION, {
    accountIdentifier: 'individual',
  });

  const { data }: ResponseGenerator<any> = yield call(HttpClient, 'PATCH', url, action.payload.data, authToken);

  yield put(
    toastMessagesAdd({
      key: uuidv4(),
      severity: SeverityEnum.Success,
      message: `Assets Information was successfully saved`,
    }),
  );

  yield put({
    type: State.actionSucceeded(Type.PATCH_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_ASSETS),
  });
  const account: Account = yield select(state => state.accounts.item.data);
  yield put(
    doSucceededGetAccount({
      ...account,
      primaryAccountHolder: {
        ...account.primaryAccountHolder,
        financialInformation: new AccountHolderFinancialInformation(data),
      },
    }),
  );
}

function* createFinancialInformationEmployment(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);

  const url = replacePlaceholders(Url.EMPLOYMENT_FINANCIAL_INFORMATION, {
    accountIdentifier: 'individual',
  });
  let bodyToSend = {};

  if (['student', 'unemployed', 'retired'].includes(action.payload?.data?.employmentStatus)) {
    bodyToSend = { employmentStatus: action.payload?.data?.employmentStatus };
  } else {
    bodyToSend = {
      employmentStatus: action.payload?.data?.employmentStatus,
      employerName: action.payload?.data?.employerName,
      jobTitle: action.payload?.data?.jobTitle,
      yearsEmployed: action.payload?.data?.yearsEmployed,
      employerAddress: {
        address1: action.payload?.data?.address1,
        address2: action.payload?.data?.address2,
        country: action.payload?.data?.country,
        state: action.payload?.data?.state,
        city: action.payload?.data?.city,
        postalCode: action.payload?.data?.postalCode,
      },
    };
  }

  try {
    const { data }: ResponseGenerator<any> = yield call(HttpClient, 'POST', url, bodyToSend, authToken);
    yield put(
      toastMessagesAdd({
        key: uuidv4(),
        severity: SeverityEnum.Success,
        message: 'Employment Information was successfully saved',
      }),
    );
    yield put({
      type: State.actionSucceeded(Type.CREATE_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_EMPLOYMENT),
    });
    const account: Account = yield select(state => state.accounts.item.data);
    yield put(
      doSucceededGetAccount({
        ...account,
        primaryAccountHolder: {
          ...account.primaryAccountHolder,
          financialInformation: new AccountHolderFinancialInformation(data),
        },
      }),
    );
  } catch (error) {
    if (isAddressMismatchError(error)) {
      yield handleAddressMismatchResponseError({
        error,
        actionType: Type.CREATE_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_EMPLOYMENT,
      });

      return;
    }
    throw error;
  }
}

function* patchFinancialInformationEmployment(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);

  const url = replacePlaceholders(Url.EMPLOYMENT_FINANCIAL_INFORMATION, {
    accountIdentifier: 'individual',
  });

  let bodyToSend = {};

  if (['student', 'unemployed', 'retired'].includes(action.payload?.data?.employmentStatus)) {
    bodyToSend = { employmentStatus: action.payload?.data?.employmentStatus };
  } else {
    bodyToSend = {
      employmentStatus: action.payload?.data?.employmentStatus,
      employerName: action.payload?.data?.employerName,
      jobTitle: action.payload?.data?.jobTitle,
      yearsEmployed: action.payload?.data?.yearsEmployed,
      employerAddress: {
        address1: action.payload?.data?.address1,
        address2: action.payload?.data?.address2,
        country: action.payload?.data?.country,
        state: action.payload?.data?.state,
        city: action.payload?.data?.city,
        postalCode: action.payload?.data?.postalCode,
      },
    };
  }

  try {
    const { data }: ResponseGenerator<any> = yield call(HttpClient, 'PATCH', url, bodyToSend, authToken);

    const account: Account = yield select(state => state.accounts.item.data);

    yield put(
      toastMessagesAdd({
        key: uuidv4(),
        severity: SeverityEnum.Success,
        message: `Employment Information was successfully saved`,
      }),
    );
    yield put({
      type: State.actionSucceeded(Type.PATCH_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_EMPLOYMENT),
    });
    yield put(
      doSucceededGetAccount({
        ...account,
        primaryAccountHolder: {
          ...account.primaryAccountHolder,
          financialInformation: new AccountHolderFinancialInformation(data),
        },
      }),
    );
  } catch (error) {
    if (isAddressMismatchError(error)) {
      yield handleAddressMismatchResponseError({
        error,
        actionType: Type.PATCH_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_EMPLOYMENT,
      });

      return;
    }
    throw error;
  }
}

export function* createDisclosures(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);
  let url = replacePlaceholders(Url.DISCLOSURES_INFORMATION, {
    accountIdentifier: 'individual',
  });

  const { data }: ResponseGenerator<any> = yield call(HttpClient, 'POST', url, action.payload.data, authToken);
  const account: Account = yield select(state => state.accounts.item.data);

  yield put(
    toastMessagesAdd({
      key: uuidv4(),
      severity: SeverityEnum.Success,
      message: 'Disclosures Information was successfully saved',
    }),
  );
  yield put({
    type: State.actionSucceeded(Type.CREATE_ACCOUNT_HOLDER_DISCLOSURES),
  });
  yield put(
    doSucceededGetAccount({
      ...account,
      primaryAccountHolder: { ...account.primaryAccountHolder, disclosure: new AccountHolderDisclosures(data) },
    }),
  );
}

export function* patchDisclosures(action: TReduxAction) {
  const { authToken }: AuthReducedState = yield select(state => state.auth.data);
  let url = replacePlaceholders(Url.DISCLOSURES_INFORMATION, {
    accountIdentifier: 'individual',
  });

  const { data }: ResponseGenerator<any> = yield call(HttpClient, 'PATCH', url, action.payload.data, authToken);
  const account: Account = yield select(state => state.accounts.item.data);
  yield put(
    toastMessagesAdd({
      key: uuidv4(),
      severity: SeverityEnum.Success,
      message: 'Disclosures Information was successfully saved',
    }),
  );
  yield put({
    type: State.actionSucceeded(Type.CREATE_ACCOUNT_HOLDER_DISCLOSURES),
  });
  yield put(
    doSucceededGetAccount({
      ...account,
      primaryAccountHolder: { ...account.primaryAccountHolder, disclosure: new AccountHolderDisclosures(data) },
    }),
  );
}

export function* registerAccountHolderSagas() {
  yield takeEvery(
    State.actionRequested(Type.CREATE_ACCOUNT_HOLDER_PERSONAL_INFORMATION),
    safeSaga(createPersonalInformation, Type.CREATE_ACCOUNT_HOLDER_PERSONAL_INFORMATION),
  );
  yield takeEvery(
    State.actionRequested(Type.PATCH_ACCOUNT_HOLDER_PERSONAL_INFORMATION),
    safeSaga(patchPersonalInformation, Type.PATCH_ACCOUNT_HOLDER_PERSONAL_INFORMATION),
  );

  yield takeEvery(
    State.actionRequested(Type.CREATE_ACCOUNT_HOLDER_PHYSICAL_ADDRESS),
    safeSaga(createPhysicalAddress, Type.CREATE_ACCOUNT_HOLDER_PHYSICAL_ADDRESS),
  );
  yield takeEvery(
    State.actionRequested(Type.PATCH_ACCOUNT_HOLDER_PHYSICAL_ADDRESS),
    safeSaga(patchPhysicalAddress, Type.PATCH_ACCOUNT_HOLDER_PHYSICAL_ADDRESS),
  );

  yield takeEvery(
    State.actionRequested(Type.CREATE_ACCOUNT_HOLDER_MAILING_ADDRESS),
    safeSaga(createMailingAddress, Type.CREATE_ACCOUNT_HOLDER_MAILING_ADDRESS),
  );
  yield takeEvery(
    State.actionRequested(Type.PATCH_ACCOUNT_HOLDER_MAILING_ADDRESS),
    safeSaga(patchMailingAddress, Type.PATCH_ACCOUNT_HOLDER_MAILING_ADDRESS),
  );

  yield takeEvery(
    State.actionRequested(Type.CREATE_ACCOUNT_HOLDER_TRUSTED_CONTACT),
    safeSaga(createTrustedContact, Type.CREATE_ACCOUNT_HOLDER_TRUSTED_CONTACT),
  );
  yield takeEvery(
    State.actionRequested(Type.PATCH_ACCOUNT_HOLDER_TRUSTED_CONTACT),
    safeSaga(patchTrustedContact, Type.PATCH_ACCOUNT_HOLDER_TRUSTED_CONTACT),
  );
  yield takeEvery(
    State.actionRequested(Type.DELETE_ACCOUNT_HOLDER_TRUSTED_CONTACT),
    safeSaga(deleteTrustedContact, Type.DELETE_ACCOUNT_HOLDER_TRUSTED_CONTACT),
  );

  yield takeEvery(
    State.actionRequested(Type.CREATE_ACCOUNT_HOLDER_SUITABILITY_INFORMATION),
    safeSaga(createSuitabilityInformation, Type.CREATE_ACCOUNT_HOLDER_SUITABILITY_INFORMATION),
  );
  yield takeEvery(
    State.actionRequested(Type.PATCH_ACCOUNT_HOLDER_SUITABILITY_INFORMATION),
    safeSaga(patchSuitabilityInformation, Type.PATCH_ACCOUNT_HOLDER_SUITABILITY_INFORMATION),
  );

  yield takeEvery(
    State.actionRequested(Type.CREATE_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_ASSETS),
    safeSaga(createFinancialInformationAssets, Type.CREATE_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_ASSETS),
  );
  yield takeEvery(
    State.actionRequested(Type.PATCH_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_ASSETS),
    safeSaga(patchFinancialInformationAssets, Type.PATCH_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_ASSETS),
  );
  yield takeEvery(
    State.actionRequested(Type.CREATE_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_EMPLOYMENT),
    safeSaga(createFinancialInformationEmployment, Type.CREATE_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_EMPLOYMENT),
  );
  yield takeEvery(
    State.actionRequested(Type.PATCH_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_EMPLOYMENT),
    safeSaga(patchFinancialInformationEmployment, Type.PATCH_ACCOUNT_HOLDER_FINANCIAL_INFORMATION_EMPLOYMENT),
  );

  yield takeEvery(
    State.actionRequested(Type.CREATE_ACCOUNT_HOLDER_DISCLOSURES),
    safeSaga(createDisclosures, Type.CREATE_ACCOUNT_HOLDER_DISCLOSURES),
  );
  yield takeEvery(
    State.actionRequested(Type.PATCH_ACCOUNT_HOLDER_DISCLOSURES),
    safeSaga(patchDisclosures, Type.PATCH_ACCOUNT_HOLDER_DISCLOSURES),
  );
}
