export interface AccountHolderAddressDto {
  address1: string;
  address2?: string;
  country: string;
  state: string;
  city: string;
  postalCode: string;
}

export enum AccountHolderMaritalStatusDto {
  Divorced = 'divorced',
  Married = 'married',
  Single = 'single',
  Widowed = 'widowed',
}

export interface AccountHolderPhysicalAddressDto extends AccountHolderAddressDto {}

export interface AccountHolderMailingAddressDto extends AccountHolderPhysicalAddressDto {
  sameAsPhysical: boolean;
}

export enum AccountStatusDto {
  Pending = 'Pending',
  New = 'New',
  InvestigationSubmitted = 'InvestigationSubmitted',
  ReadyForBackOffice = 'ReadyForBackOffice',
  BackOffice = 'BackOffice',
  AccountSetup = 'AccountSetup',
  Complete = 'Complete',
  ActionRequired = 'ActionRequired',
  Rejected = 'Rejected',
  Suspended = 'Suspended',
  Error = 'Error',
  Canceled = 'Canceled',
  PendingSubmit = 'PendingSubmit',
}

export enum AccountHolderStatementDeliveryDto {
  Electronic = 'electronic',
  Paper = 'paper',
}
export interface AccountHolderDisclosureDto {
  userId: string;
  statementDelivery: AccountHolderStatementDeliveryDto;
  stakeholder: false | { tickerSymbol: string };
  industryEmployed: false | { firmName: string };
}

export enum AccountHolderAnnualIncomeDto {
  LessThan25K = 'lessThan25K',
  Between25KAnd50K = 'between25KAnd50K',
  Between50KAnd100K = 'between50KAnd100K',
  Between100KAnd200K = 'between100KAnd200K',
  Between200KAnd300K = 'between200KAnd300K',
  Between300KAnd500K = 'between300KAnd500K',
  Between500KAnd1Point2M = 'between500KAnd1Point2M',
  MoreThan1Point2M = 'moreThan1Point2M',
}

export enum AccountHolderTotalNetWorthDto {
  LessThan50K = 'lessThan50K',
  Between50KAnd100K = 'between50KAnd100K',
  Between100KAnd200K = 'between100KAnd200K',
  Between200KAnd500K = 'between200KAnd500K',
  Between500KAnd1M = 'between500KAnd1M',
  Between1MAnd5M = 'between1MAnd5M',
  MoreThan5M = 'moreThan5M',
}

export enum AccountHolderLiquidNetWorthDto {
  LessThan50K = 'lessThan50K',
  Between50KAnd100K = 'between50KAnd100K',
  Between100KAnd200K = 'between100KAnd200K',
  Between200KAnd500K = 'between200KAnd500K',
  Between500KAnd1M = 'between500KAnd1M',
  Between1MAnd5M = 'between1MAnd5M',
  MoreThan5M = 'moreThan5M',
}

export enum AccountHolderTaxBracketDto {
  Bottom = 'bottom',
  Middle = 'middle',
  Top = 'top',
}

export enum AccountHolderEmploymentStatusDto {
  Employed = 'employed',
  Retired = 'retired',
  Student = 'student',
  Unemployed = 'unemployed',
}

export enum AccountHolderYearsEmployedDto {
  BetweenZeroAndOne = 'betweenZeroAndOne',
  BetweenTwoAndFive = 'betweenTwoAndFive',
  BetweenFiveAndTen = 'betweenFiveAndTen',
  BetweenTenAndTwenty = 'betweenTenAndTwenty',
  MoreThanTwenty = 'moreThanTwenty',
}

export interface AccountHolderFinancialInformationDto {
  annualIncome?: AccountHolderAnnualIncomeDto;
  totalNetWorth?: AccountHolderTotalNetWorthDto;
  liquidNetWorth?: AccountHolderLiquidNetWorthDto;
  taxBracket?: AccountHolderTaxBracketDto;
  employmentStatus: AccountHolderEmploymentStatusDto;
  employerName?: string;
  jobTitle?: string;
  yearsEmployed?: AccountHolderYearsEmployedDto;
  employerAddress?: AccountHolderAddressDto;
}

export enum AccountHolderTradesPerYearDto {
  OneToFive = 'OneToFive',
  SixToTen = 'SixToTen',
  ElevenToTwenty = 'ElevenToTwenty',
  MoreThanTwenty = 'MoreThanTwenty',
}

export enum AccountHolderYearsOfExperienceDto {
  One = 'One',
  TwoToThree = 'TwoToThree',
  FourToNine = 'FourToNine',
  TenOrMore = 'TenOrMore',
}

export enum AccountHolderLiquidityNeedsDto {
  NotImportant = 'notImportant',
  SomewhatImportant = 'somewhatImportant',
  VeryImportant = 'veryImportant',
}

export enum AccountHolderInvestmentObjectivesDto {
  CapitalPreservation = 'capitalPreservation',
  Income = 'income',
  Growth = 'growth',
  GrowthAndIncome = 'growthAndIncome',
  Speculation = 'speculation',
}

export enum AccountHolderRiskToleranceDto {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
}

export enum AccountHolderTimeHorizonDto {
  Short = 'short',
  Average = 'average',
  Long = 'long',
}

export interface AccountHolderFinancialInvestmentExperienceTypeDto {
  yearsOfExperience: AccountHolderYearsOfExperienceDto;
  tradesPerYear: AccountHolderTradesPerYearDto;
}

export interface AccountHolderInvestmentExperienceDto {
  stocks?: AccountHolderFinancialInvestmentExperienceTypeDto;
  options?: AccountHolderFinancialInvestmentExperienceTypeDto;
  commodities?: AccountHolderFinancialInvestmentExperienceTypeDto;
  bonds?: AccountHolderFinancialInvestmentExperienceTypeDto;
  margins?: AccountHolderFinancialInvestmentExperienceTypeDto;
}

export interface AccountHolderSuitabilityInformationDto {
  liquidityNeeds: AccountHolderLiquidityNeedsDto;
  investmentObjectives: AccountHolderInvestmentObjectivesDto;
  riskTolerance: AccountHolderRiskToleranceDto;
  timeHorizon: AccountHolderTimeHorizonDto;
  financialInvestmentExperience?: AccountHolderInvestmentExperienceDto;
}

export enum AccountHolderPhoneNumberTypeDto {
  Home = 'Home',
  Work = 'Work',
  Mobile = 'Mobile',
}

export type AccountHolderPhoneNumberDto = {
  phoneNumber: string;
  type: AccountHolderPhoneNumberTypeDto;
};

export interface AccountHolderTrustedContactDto extends AccountHolderAddressDto {
  firstName: string;
  lastName: string;
  email: string;
  phones: Array<AccountHolderPhoneNumberDto>;
}

export interface AccountHolderDto {
  id?: string;
  taxId?: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  suffix?: string;
  dateOfBirth?: string;
  maritalStatus?: AccountHolderMaritalStatusDto;
  numberOfDependents?: number;
  birthCountry?: string;
  citizenshipCountry?: string;
  physicalAddress?: AccountHolderPhysicalAddressDto;
  mailingAddress?: AccountHolderMailingAddressDto;
  suitabilityInformation?: AccountHolderSuitabilityInformationDto;
  trustedContact?: AccountHolderTrustedContactDto;
  financialInformation?: AccountHolderFinancialInformationDto;
  disclosure?: AccountHolderDisclosureDto;
}
