import { useEffect } from 'react';

import { Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useAccountSelector } from 'src/hooks';

import { getInvestigations } from '../../../actions';
import { RadioGroupValue } from '../../../lib/FormComponents/RadioGroup/constants';
import { MyAccountLayout } from '../../../lib/Layout/MyAccountLayout/MyAccountLayout';
import { MyAccountSidebarOtherMenuItemKey } from '../../../lib/Layout/MyAccountLayout/MyAccountSidebar';
import Spinner from '../../../lib/Miscellaneous/Spinner';
import { AccountStatusEnum, AccountStatusLabel } from '../../../models/account-status.enum';
import {
  Application,
  Category,
  InvestigationDocumentName,
  Investigation as InvestigationType,
  UploadedDocument,
} from '../../../typings/investigationTypes';
import ApplicationInvestigation from '../components/ApplicationInvestigation/ApplicationInvestigation';
import Investigation from '../components/Investigation/Investigation';
import { IrsW9FormDownloadLink } from '../components/IrsW9FormDownloadLink/IrsW9FormDownloadLink';
import SubmittedDocuments from '../components/SubmittedDocument/SubmittedDocument';

import * as Styles from './AccountStatus.styles';
import {
  getAccountName,
  getHeaderLabel,
  getInvestigationDescription,
  getInvestigationStatus,
  getTagStyle,
  uploadProps,
} from './constants';

export const AccountStatus = () => {
  const dispatch = useDispatch();

  const investigationState = useSelector((state: any) => state.investigations?.investigationsList?.data?.state);
  const applications = useSelector((state: any) => state.investigations?.investigationsList?.data?.applications);
  const investigations = useSelector((state: any) => state.investigations?.investigationsList?.data?.investigations);
  const isLoadingInvestigations = useSelector((state: any) =>
    Boolean(state.investigations.investigationsList?.__requested),
  );
  const { account } = useAccountSelector();

  useEffect(() => {
    if (account?.accountId) dispatch(getInvestigations(account?.accountId));
  }, [dispatch, account?.accountId]);

  if (isLoadingInvestigations) {
    return <Spinner />;
  }

  return (
    <MyAccountLayout sidebarMenuItemKey={MyAccountSidebarOtherMenuItemKey.AccountStatus} title='Account status'>
      <Row>
        <Col>
          <div className={Styles.formCard}>
            <div className={Styles.statusRow}>
              <span className={Styles.statusLabel}>Current Status</span>
              <div style={getTagStyle(investigationState)}>
                {AccountStatusLabel.get(getInvestigationStatus(investigationState))}
              </div>
            </div>
            <div>{getInvestigationDescription(investigationState, applications, investigations)}</div>
            <div className={Styles.contactLabel}>Phone: (844) 226-0640</div>
            <div className={Styles.contactLabel}>
              Email: <a href={`mailto:clientservices@myipo.com`}>clientservices@myipo.com</a>
            </div>
            <span className={Styles.statusLabel}>
              {getHeaderLabel(investigations, applications, investigationState)}
            </span>
            {getInvestigationStatus(investigationState) === AccountStatusEnum.PENDING && (
              <>
                {applications &&
                  applications.length > 0 &&
                  applications.map((application: Application) =>
                    application?.appCategories
                      ?.filter((category: Category) => category.requestedDocuments !== null)
                      .map((appCategory: Category) => {
                        const options = appCategory?.requestedDocuments.map((document: string) => {
                          return {
                            value: document,
                            text: getAccountName(document) || '',
                          };
                        });
                        const documentSubmitted: UploadedDocument = application?.uploadedDocuments?.find(
                          (document: UploadedDocument) =>
                            appCategory?.requestedDocuments.includes(document.documentName),
                        );

                        return (
                          <div key={`${appCategory?.name}_key`}>
                            <ApplicationInvestigation
                              appCategory={appCategory}
                              options={options}
                              documentSubmitted={documentSubmitted}
                              application={application}
                              dispatch={dispatch}
                              accountId={account?.accountId}
                              uploadProps={uploadProps}
                            />
                          </div>
                        );
                      }),
                  )}
                {investigations &&
                  investigations.length > 0 &&
                  investigations.map((investigation: InvestigationType) =>
                    investigation?.cipCategories
                      ?.filter((category: Category) => category.requestedDocuments !== null)
                      .map((cipCategory: Category) => {
                        const options = cipCategory?.requestedDocuments.map((document: InvestigationDocumentName) => {
                          let option: RadioGroupValue = {
                            value: document,
                            text: getAccountName(document) || '',
                          };

                          if (document === InvestigationDocumentName.IrsW9Form) {
                            option.subComponent = <IrsW9FormDownloadLink />;
                          }

                          return option;
                        });

                        const documentSubmitted: UploadedDocument | undefined = investigation?.uploadedDocuments?.find(
                          (document: UploadedDocument) =>
                            cipCategory?.requestedDocuments.includes(document.documentName),
                        );

                        return (
                          <div key={`${cipCategory?.name}_key`}>
                            <Investigation
                              cipCategory={cipCategory}
                              options={options}
                              documentSubmitted={documentSubmitted}
                              investigation={investigation}
                              dispatch={dispatch}
                              accountId={account?.accountId}
                              uploadProps={uploadProps}
                            />
                          </div>
                        );
                      }),
                  )}
              </>
            )}
            {getInvestigationStatus(investigationState) === AccountStatusEnum.ACTIVE && (
              <>
                {investigations &&
                  investigations.length > 0 &&
                  investigations.map((investigation: InvestigationType) =>
                    investigation?.uploadedDocuments?.map((document: UploadedDocument) => (
                      <div key={document.miscDocumentId} className={Styles.documentContainer}>
                        <SubmittedDocuments document={document} />
                      </div>
                    )),
                  )}
              </>
            )}
          </div>
        </Col>

        <Col span={3} />
      </Row>
    </MyAccountLayout>
  );
};
