import { useEffect } from 'react';

import { Col, Row } from 'antd';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toggleMfaConfig, clearMfaConfigToggle, doRemoveOfferInvestReferrer } from 'src/actions';
import { UserDto } from 'src/dtos';
import { MFormSwitch, MButton } from 'src/lib';
import { OnboardStep } from 'src/models/app.models';
import { Spacing } from 'src/styles';

import { ActiveTab } from '../constants';
import { SignUpWithOfferInvestReferrer } from '../SignUpWithOfferInvestReferrer';

import * as SecurityStyles from './Security.styles';

interface ToggleMfaFormValues {
  isEnabled?: boolean;
}

export const Security = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userData: UserDto = useSelector((state: any) => state.user.authenticated.data);
  const onboardStep: OnboardStep | undefined = useSelector((state: any) => state.app.onboardStep);

  const isRequested = useSelector((state: any) => Boolean(state.user.toggleMfaConfig.__requested));
  const isToggleMfaSucceded = useSelector((state: any) => Boolean(state.user.toggleMfaConfig.__succeeded));

  const phoneNumber = useSelector((state: any) => state.user.signUp.data?.phoneNumber);

  const availableNumber = phoneNumber || userData.phoneNumber;

  useEffect(() => {
    if (isToggleMfaSucceded && onboardStep === OnboardStep.MfaConfirm) {
      window.gtag('event', 'sign_up_security_complete', {
        mfa_enabled: true,
      });

      return navigate('/security-confirmation');
    }

    if (isToggleMfaSucceded && onboardStep === OnboardStep.Done) {
      dispatch(doRemoveOfferInvestReferrer());
      window.gtag('event', 'sign_up_security_complete', {
        mfa_enabled: false,
      });

      return navigate('/complete-account');
    }
  }, [isToggleMfaSucceded, onboardStep]);

  useEffect(() => {
    return () => {
      dispatch(clearMfaConfigToggle());
    };
  }, []);

  return (
    <SignUpWithOfferInvestReferrer
      activeTab={ActiveTab.Security}
      tabs={[ActiveTab.Register, ActiveTab.Address, ActiveTab.Security]}
      cardTitle='Multi-factor authentication'
      cardSubtitle='For added security, you must authenticate your identity. By clicking "Next" we will text a
    One Time Security Code to the number below. Do not share this code with anyone.'>
      <Formik<ToggleMfaFormValues>
        initialValues={{
          isEnabled: userData.isMfaEnabled ?? false,
        }}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={values => {
          dispatch(toggleMfaConfig(values));
        }}>
        {form => {
          return (
            <>
              <MFormSwitch
                label='Enable SMS Confirmation'
                alignment='right'
                defaultChecked={userData.isMfaEnabled}
                checked={form.values?.isEnabled}
                onChange={(checked: boolean) => {
                  form.setFieldValue('isEnabled', checked);
                }}
              />
              {form.values.isEnabled && (
                <Row>
                  <Col span={24}>
                    Your phone number is:{' '}
                    {availableNumber && (
                      <span className={SecurityStyles.phoneNumberSpan}>
                        {`XXX - XXX - ${availableNumber.substr(availableNumber.length - 4)}`}
                      </span>
                    )}
                  </Col>
                </Row>
              )}

              <Row justify={'center'} className={Spacing.my12}>
                <MButton
                  testId={'security-btn-back'}
                  type='tertiary'
                  className={Spacing.m8}
                  onClick={() => {
                    navigate('/address', {
                      state: {
                        title: 'back-from-security',
                      },
                    });
                  }}>
                  Back
                </MButton>
                <MButton
                  testId={'security-btn-next'}
                  type='secondary'
                  className={Spacing.m8}
                  onClick={() => {
                    form.submitForm();
                  }}
                  disabled={isRequested || !form.isValid}
                  loading={isRequested}>
                  Next
                </MButton>
              </Row>
            </>
          );
        }}
      </Formik>
    </SignUpWithOfferInvestReferrer>
  );
};
