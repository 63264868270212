import { MFormItem } from '../MFormItem/MFormItem';
import { MInput, MInputProps } from '../MInput/MInput';

export interface MFormInputProps extends MInputProps {
  label: string;
  error?: string;
  tooltip?: {
    type: 'modal' | 'popover';
    title: string;
    content: string | JSX.Element;
  };
  size?: 'small' | 'middle' | 'large';
  weight?: 'light' | 'medium';
  testId?: string;
}

export const MFormInput = ({
  label,
  placeholder,
  value,
  defaultValue,
  error,
  onChange,
  disabled,
  size,
  weight,
  tooltip,
  addOnBefore,
  addOnAfter,
  className,
  testId,
}: MFormInputProps) => {
  return (
    <MFormItem label={label} tooltip={tooltip} weight={weight} testId={testId + '-' + 'label'}>
      <MInput
        testId={testId + '-' + 'input'}
        value={value}
        defaultValue={defaultValue}
        size={size}
        onChange={onChange}
        placeholder={placeholder}
        error={error}
        disabled={disabled}
        addOnBefore={addOnBefore}
        addOnAfter={addOnAfter}
        className={className}
        weight={weight}
      />
    </MFormItem>
  );
};
