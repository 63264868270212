export enum RadioAlignment {
  Left = 'left',
  Right = 'right',
}

export type RadioGroupCompType = {
  name: string;
  label?: string;
  value?: string;
  disabled?: boolean;
  errors?: any;
  touched?: any;
  setFieldValue: Function;
  setFieldTouched: Function;
  preChangeHook?: any;
  postChangeHook?: any;
  options: Array<RadioGroupValue>;
  radioAlignment?: RadioAlignment;
  divided?: boolean;
  onSubComponentClick?: any;
  showSubComponentOnSelectOnly?: boolean;
  inLined?: boolean;
  testId?: string;
};

export type RadioGroupValue = {
  value: string;
  text: any;
  subLabel?: string;
  tip?: string;
  tooltip?: string;
  subComponent?: any;
  warning?: string;
};
