import React from 'react';

import { FontWeight, Images } from '../../../../styles';

import * as Styles from './styles';

const BankLinkSubDisclaimer = () => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}>
        <img
          alt='bank'
          src={Images.BankPlaceholder}
          style={{
            marginTop: 16,
            marginBottom: 16,
            width: 40,
            height: 40,
          }}
        />
      </div>
      <p className={Styles.text} data-testid={'link-bank-disclaimer-text'}>
        By entering your bank account information, you are authorizing My IPO to communicate your bank account
        information to TradingBlock/Apex Clearing, Inc. (&quot;APEX&quot;), My IPO&apos;s chosen custodian.
        <br />
        You also authorize My IPO as a chosen medium to make transfers between this bank account and your account that
        is held at APEX.
      </p>
      <p className={Styles.text}>
        <span className={FontWeight.bold}>My IPO does not accept “third party” funding of any kind</span>. This means
        that the name on your bank account must match the name on your My IPO account exactly. For example, money
        transfers from a trust or business bank account (owned by you) to an individual or joint My IPO account are
        considered third-party and <span className={FontWeight.bold}>will not be accepted.</span>
      </p>
    </>
  );
};

export default BankLinkSubDisclaimer;
